import { datadogRum } from '@datadog/browser-rum';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import React, { ReactElement, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import AddressEntry from './components/AddressEntry';
import ApplicationErrorWrapper from './components/ApplicationErrorWrapper';
import DebitCardEntry from './components/DebitCardEntry';
import ErrorPage from './components/ErrorPage';
import FourOhFour from './components/FourOhFour';
import CountryEntry from './components/InternationalBirthCountry';
import LoanOptions from './components/LoanOptions';
import LoanSuccessInStore from './components/LoanSuccessInStore';
import LoanSummary from './components/LoanSummary';
import MobileVerificationEntry from './components/MobileVerificationEntry';
import OccupationEntry from './components/OccupationEntry';
import PersonalInfoEntry from './components/PersonalInfoEntry';
import PhoneEntry from './components/PhoneEntry';
import PinEntry from './components/PinEntry';
import PostMessageWrapper from './components/PostMessageWrapper';
import PrivateRoute from './components/PrivateRoute';
import VerifyPersonalInfo from './components/VerifyPersonalInfo';
import DevCheckout from './dev/components/DevCheckout';
import { updateAppStarted } from './ducks/analytics';
import i18next from './localization/i18n';
import store, { persistor } from './Store';
import UseSift from './util/UseSift';

function App(): ReactElement {
  useEffect(() => {
    document.title = i18next.t('app.title');
    store.dispatch(updateAppStarted());
  }, []);

  datadogRum.init({
    applicationId: '5b644d4e-3320-4f45-ab0e-54a52deba587',
    clientToken: 'pub30aafa1294e46ee9c93e8c333e9f357f',
    site: 'datadoghq.com',
    service: 'web-checkout',
    sampleRate: 100,
    trackInteractions: false
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <Router>
            <ApplicationErrorWrapper>
              <UseSift>
                <PostMessageWrapper>
                  <Switch>
                    <Route path="/phone-entry">
                      <PhoneEntry />
                    </Route>
                    <Route path="/confirm-code">
                      <MobileVerificationEntry />
                    </Route>
                    <PrivateRoute requiredAuthActions={['LOGIN']} path="/pin-entry">
                      <PinEntry />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['SIGNUP']} path="/signup/personal-info">
                      <PersonalInfoEntry />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['SIGNUP']} path="/signup/verify-personal-info">
                      <VerifyPersonalInfo />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['SIGNUP']} path="/signup/country-entry">
                      <CountryEntry />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['SIGNUP']} path="/signup/occupation-entry">
                      <OccupationEntry />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['SIGNUP']} path="/signup/address-entry">
                      <AddressEntry />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['API']} path="/loan-options">
                      <LoanOptions />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['API']} path="/down-payment/:optionUuid">
                      <DebitCardEntry />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['API']} path="/payment-option/:uuid">
                      <LoanSummary />
                    </PrivateRoute>
                    <PrivateRoute requiredAuthActions={['API']} path="/in-store/success">
                      <LoanSuccessInStore />
                    </PrivateRoute>
                    <Route path="/error">
                      <ErrorPage />
                    </Route>
                    <Route path="/dev">
                      <DevCheckout />
                    </Route>
                    <Route path="*">
                      <FourOhFour />
                    </Route>
                  </Switch>
                </PostMessageWrapper>
              </UseSift>
            </ApplicationErrorWrapper>
          </Router>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
