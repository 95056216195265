import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { EmptyAction } from 'typesafe-actions';

import { exitCheckoutAction } from '../ducks/application';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  questionText: {
    fontWeight: 'bold'
  },
  button: {
    marginTop: spacing.spacing3x,
    marginBottom: spacing.spacing3x,
    paddingLeft: spacing.spacing4x,
    paddingRight: spacing.spacing4x,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x
  }
});

interface UnsupportedLoanAmountProps {
  exitCheckout: () => void;
  minAllowedAmount: number;
}

function UnsupportedLoanAmount(props: UnsupportedLoanAmountProps): ReactElement {
  const classes = useStyles();
  return (
    <NeloBrandWrapper title={i18next.t('errors.loanOption.unsupportedAmount.title')}>
      <Grid container direction="column">
        <Typography variant="body1" className={classes.questionText}>
          {i18next.t('errors.loanOption.unsupportedAmount.description', { minAllowedAmount: props.minAllowedAmount })}
        </Typography>
        <NeloButton
          text={i18next.t('loanOptions.rejected.submitButtonText')}
          className={classes.button}
          onClick={(): void => props.exitCheckout()}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  exitCheckout: (): EmptyAction<string> => exitCheckoutAction()
};

export default connect(null, mapDispatchToProps)(UnsupportedLoanAmount);
