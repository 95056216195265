import { StrictEffect } from '@redux-saga/types';
import { History } from 'history';
import jwt from 'jwt-decode';
import { all, call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { InvalidApplicationStateError } from '../errors/InvalidApplicationState';
import { AuthAction } from '../interfaces/nelo-api/AuthAction';
import { LoginResponse } from '../interfaces/nelo-api/Login';
import { doNeloApiRequest, doNeloApiRequestWithResponse, NeloApiRequestFn, safeCall } from '../util/neloApiRequest';
import { getState as getApplicationState, logout } from './application';

export const updateAuthState = createAction('auth/UPDATE_AUTH_STATE')<AuthCredentials>();
export const updateBerbixTokenState = createAction('auth/UPDATE_BERBIX_TOKEN_STATE')<AuthBerbixToken>();
export const updateOnfidoTokenState = createAction('auth/UPDATE_ONFIDO_TOKEN_STATE')<AuthOnfidoToken>();
export const updateIsVerified = createAction('auth/UPDATE_IS_VERIFIED')<boolean>();
export const updatePin = createAction('auth/UPDATE_PIN_CODE')<string>();
export const submitPin = createAction('auth/SUBMIT_PIN')<History>();
export const submitToken = createAction('auth/SUBMIT_TOKEN')<AuthToken>();
export const submitTransaction = createAction('auth/SUBMIT_TRANSACTION_TOKEN')();
export const fetchOnfidoToken = createAction('auth/CREATE_ONFIDO_APPLICANT')();
export const startOnfidoCheck = createAction('auth/CREATE_ONFIDO_CHECK')();

export interface AuthToken {
  token: string;
  userUuid: string;
  history: History;
}
export interface AuthCredentials {
  accessToken: string;
  authAction: AuthAction;
  userUuid: string;
}

interface AuthBerbixToken {
  berbixToken: string;
}

interface AuthOnfidoToken {
  sdk_token: string;
}

export interface AuthState {
  accessToken: string | null;
  authAction: AuthAction | null;
  pin: string;
  userUuid: string;
  berbixToken: string | null;
  onfidoToken: string | null;
  isVerified: boolean;
}

export const initialState: AuthState = {
  accessToken: null,
  authAction: null,
  userUuid: '',
  pin: '',
  berbixToken: null,
  onfidoToken: null,
  isVerified: false
};

interface CreateTransactionResponse {
  transactionId: string;
  expiresAt: string;
  clientToken: string;
}

interface CreateOnfidoApplicantResponse {
  sdk_token: string;
}

export const getState = ({ auth }: { auth: AuthState }): AuthState => auth;

const doLogout = (state: AuthState): AuthState => ({
  ...state,
  accessToken: null,
  authAction: null,
  userUuid: '',
  pin: ''
});

const setCredentials = (state: AuthState, action: PayloadAction<string, AuthCredentials>): AuthState => ({
  ...state,
  accessToken: action.payload.accessToken,
  authAction: action.payload.authAction,
  userUuid: action.payload.userUuid,
  pin: ''
});

const setBerbixToken = (state: AuthState, action: PayloadAction<string, AuthBerbixToken>): AuthState => ({
  ...state,
  berbixToken: action.payload.berbixToken
});

const setOnfidoToken = (state: AuthState, action: PayloadAction<string, AuthOnfidoToken>): AuthState => ({
  ...state,
  onfidoToken: action.payload.sdk_token
});

const setIsVerified = (state: AuthState, action: PayloadAction<string, boolean>): AuthState => ({
  ...state,
  isVerified: action.payload
});

const setPin = (state: AuthState, action: PayloadAction<string, string>): AuthState => ({
  ...state,
  pin: action.payload
});

function* submitTokenSaga(action: PayloadAction<string, AuthToken>): Generator<StrictEffect, void, void> {
  const token = action.payload.token;
  const userUuid = action.payload.userUuid;
  const decoded: { action: AuthAction } = jwt(token);
  const authAction = decoded.action;
  const history = action.payload.history;

  yield* put(
    updateAuthState({
      accessToken: token,
      authAction,
      userUuid
    })
  );

  if (authAction === 'API') {
    history.push(`/loan-options`);
  } else {
    history.push('/pin-entry');
    throw new InvalidApplicationStateError(`Invalid authAction. Was ${authAction}`);
  }
}

function* submitTransactionToken(): Generator<StrictEffect, void, void> {
  try {
    const tokenResponseBody: CreateTransactionResponse = yield* call<NeloApiRequestFn<CreateTransactionResponse>>(
      doNeloApiRequestWithResponse,
      NeloApiClient.createBerbixTransaction.bind(NeloApiClient),
      true
    );
    const { clientToken } = tokenResponseBody;
    yield* put(
      updateBerbixTokenState({
        berbixToken: clientToken
      })
    );
  } catch (err) {
    throw new InvalidApplicationStateError(`Invalid authAction. Berbix token not generated}`);
  }
}

function* createOnfidoApplicant(): Generator<StrictEffect, void, void> {
  try {
    const tokenResponseBody: CreateOnfidoApplicantResponse = yield* call<
      NeloApiRequestFn<CreateOnfidoApplicantResponse>
    >(doNeloApiRequestWithResponse, NeloApiClient.createOnfidoApplicant.bind(NeloApiClient), true);
    const { sdk_token } = tokenResponseBody;

    yield* put(
      updateOnfidoTokenState({
        sdk_token: sdk_token
      })
    );
  } catch (err) {
    throw new InvalidApplicationStateError(`Invalid authAction. Onfido token not generated}`);
  }
}

function* createOnfidoCheck(): Generator<StrictEffect, void, void> {
  try {
    yield* call<NeloApiRequestFn<void>>(doNeloApiRequest, NeloApiClient.createOnfidoCheck.bind(NeloApiClient), true);
  } catch (err) {
    throw new InvalidApplicationStateError(`Invalid authAction. Onfido token not generated}`);
  }
}

function* submitPinSaga(action: PayloadAction<string, History>): Generator<StrictEffect, void, void> {
  const state = yield* select(getState);
  const pin = state.pin;

  const history = action.payload;

  const responseBody = yield* call<NeloApiRequestFn<LoginResponse>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.loginWithPinCode.bind(NeloApiClient, { pin })
  );
  const { authAction, token, userUuid } = responseBody;
  yield* put(
    updateAuthState({
      accessToken: token,
      authAction,
      userUuid
    })
  );

  if (authAction === 'API') {
    const applicationState = yield* select(getApplicationState);
    history.push(`/loan-options?checkoutToken=${applicationState.checkoutToken}`);
  } else {
    throw new InvalidApplicationStateError(`Invalid authAction. Was ${authAction}`);
  }
}

export function* authSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(submitPin, safeCall, submitPinSaga),
    takeEvery(submitToken, safeCall, submitTokenSaga),
    takeEvery(submitTransaction, safeCall, submitTransactionToken),
    takeEvery(fetchOnfidoToken, safeCall, createOnfidoApplicant),
    takeEvery(startOnfidoCheck, safeCall, createOnfidoCheck)
  ]);
}

export default createReducer(initialState)
  .handleAction(updateAuthState, setCredentials)
  .handleAction(updateBerbixTokenState, setBerbixToken)
  .handleAction(updateOnfidoTokenState, setOnfidoToken)
  .handleAction(updateIsVerified, setIsVerified)
  .handleAction(logout, doLogout)
  .handleAction(updatePin, setPin);
