import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

import i18next from '../localization/i18n';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  button: {
    marginTop: spacing.spacing3x
  }
});

function FourOhFour(): ReactElement {
  const classes = useStyles();
  return (
    <NeloBrandWrapper
      title={i18next.t('fourOhFourPage.title')}
      subtitle={i18next.t('fourOhFourPage.subtitle')}
      description={i18next.t('fourOhFourPage.description')}
    >
      <Grid container direction="column">
        <NeloButton
          text={i18next.t('fourOhFourPage.buttonText')}
          className={classes.button}
          onClick={(): void => {
            window.location.href = 'https://nelo.mx';
          }}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

export default FourOhFour;
