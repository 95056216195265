const generateHeaders = (): Record<string, string> => {
  return {
    Authorization:
      'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNDMzNDY4ZS0zNTZiLTQ2MDktOWRjOS1kODBhZjA1MjY1NTAiLCJpc3MiOiJuZWxvLmNvIiwiYWN0aW9uIjoiTUVSQ0hBTlRfQVBJIiwiaWF0IjoxNjIxOTA1NTA5fQ.j-IRZ2OhBdh7XRX2pyt7ZLXZ2pZSjj_YlrWunpzkzEGdUmbwH0Lpu_3cCfnvWC-o_RODvQkUoJo0vWVze-sMIw',
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
    'x-app-version': '12',
    'x-app-version-code': '1',
    'x-device-platform': 'web',
    'x-device-timezone': 'America/Los_Angeles',
    'x-device-id': 'mock-merchant',
    'x-device-manufacturer': 'Nelo',
    'x-device-model': 'dev checkout'
  };
};

const generateRequest = (): Record<string, any> => {
  return {
    customer: {
      firstName: 'James',
      paternalLastName: 'Brown',
      email: 'jamesbrown@nelo.co',
      phoneNumber: {
        number: '9175550161',
        countryIso2: 'US'
      },
      address: {
        countryIso2: 'MX',
        addressMX: {
          buildingNumber: '77',
          street: 'San Luis Potosi',
          city: 'Ciudad de Mexico',
          delegation: 'Roma Norte',
          state: 'CDMX',
          colony: 'Roma Norte',
          postalCode: '06700'
        }
      }
    },
    order: {
      reference: Math.random().toString(36).slice(2).toUpperCase(), // Generates random alphanumeric string
      items: [],
      totalAmount: {
        currencyCode: 'MXN',
        amount: 5000
      }
    },
    redirectCancelUrl: `${window.location.origin}/canceled`,
    redirectConfirmUrl: `${window.location.origin}/success`
  };
};

export const generateDevCheckout = async (): Promise<string | undefined> => {
  const request = generateRequest();

  const options = {
    method: 'POST',
    headers: generateHeaders(),
    body: JSON.stringify(request)
  };

  try {
    const response = await fetch('https://api-v2-dev.nelo.co/checkout/order', options);
    if (!response.ok) {
      console.log(response.status);
      return;
    }
    const data = await response.json();
    const query = new URL(data.redirectUrl);
    const checkoutToken = query.search;
    const newLocation = `${window.location.origin}/loan-options${checkoutToken}`;
    return newLocation;
  } catch (error) {
    console.log(error);
    return;
  }
};
