import { StrictEffect } from '@redux-saga/types';
import { History } from 'history';
import { all, call, put, SagaGenerator, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { ApiError } from '../errors/NeloApi';
import { doNeloApiRequestWithResponse, NeloApiRequestFn } from '../util/neloApiRequest';
import { emitAnalyticEventAction } from './analytics';
import { updateBlockingNetworkRequest, updateErrorMessage } from './application';

export const updateCardName = createAction('downPayment/UPDATE_CARD_NAME')<string>();
export const updateNeloCards = createAction('downPayment/UPDATE_NELO_CARDS')<NeloCard[]>();
export const updateLoadingNeloCards = createAction('downPayment/UPDATE_LOADING_NELO_CARDS')<boolean>();
export const createNeloCard = createAction('downPayment/CREATE_NELO_CARD')<CreateCard>();

export const updatePostalCode = createAction('downPayment/UPDATE_POSTAL_CODE')<string>();
export const getUserNeloCards = createAction('downPayment/GET_NELO_CARDS')();

export interface CreateCardRequest {
  cardToken: string;
  lastFourDigits: string | undefined;
  expirationMonth: string | undefined;
  expirationYear: string | undefined;
  brand: string | undefined;
}

export interface CreateCard {
  history: History;
  optionUuid: string;
  request: CreateCardRequest;
}

export interface NeloCard {
  uuid: string;
  lastFourDigits: string;
  expirationMonth: string;
  expirationYear: string;
  brand: string;
  // If Nelo card contains cardDetails, it means it's a new card
}

export interface DownPaymentState {
  postalCode: string;
  fullName: string;
  neloCards: NeloCard[];
  isLoadingCards: boolean;
}

export const initialState: DownPaymentState = {
  postalCode: '',
  fullName: '',
  neloCards: [],
  isLoadingCards: true
};

const setCardPostalCode = (state: DownPaymentState, action: PayloadAction<string, string>): DownPaymentState => ({
  ...state,
  postalCode: action.payload
});

const setCardName = (state: DownPaymentState, action: PayloadAction<string, string>): DownPaymentState => ({
  ...state,
  fullName: action.payload
});

const setLoadingNeloCards = (state: DownPaymentState, action: PayloadAction<string, boolean>): DownPaymentState => {
  return {
    ...state,
    isLoadingCards: action.payload
  };
};

const setNeloCards = (state: DownPaymentState, action: PayloadAction<string, NeloCard[]>): DownPaymentState => {
  return {
    ...state,
    neloCards: action.payload
  };
};

function* getNeloCards(): Generator<StrictEffect, void, void> {
  yield* put(updateLoadingNeloCards(true));
  try {
    const responseBody = yield* call<NeloApiRequestFn<NeloCard[]>>(
      doNeloApiRequestWithResponse,
      NeloApiClient.getCards.bind(NeloApiClient)
    );
    yield* put(updateNeloCards(responseBody));
  } catch (err) {
    if (err instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: err.getMessage() }));
    } else {
      throw err;
    }
  } finally {
    yield* put(updateLoadingNeloCards(false));
  }
}

function* createCard(action: PayloadAction<string, CreateCard>): Generator<StrictEffect, void, void> {
  const history = action.payload.history;
  const optionUuid = action.payload.optionUuid;
  yield* put(updateBlockingNetworkRequest(true));
  try {
    yield* call<NeloApiRequestFn<NeloCard[]>>(
      doNeloApiRequestWithResponse,
      NeloApiClient.createCard.bind(NeloApiClient, action.payload.request)
    );
    history.push(`/payment-option/${optionUuid}`);
    yield* put(emitAnalyticEventAction({ name: 'CARD_CREATION_SUCCESS_BACKEND' }));
  } catch (err) {
    yield* put(emitAnalyticEventAction({ name: 'CARD_CREATION_ERROR_BACKEND' }));
    if (err instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: err.getMessage() }));
    } else {
      throw err;
    }
  } finally {
    yield* put(updateBlockingNetworkRequest(false));
  }
}

export function* downPaymentSaga(): SagaGenerator<void> {
  yield* all([takeEvery(getUserNeloCards, getNeloCards), takeEvery(createNeloCard, createCard)]);
}

export default createReducer(initialState)
  .handleAction(updateCardName, setCardName)
  .handleAction(updatePostalCode, setCardPostalCode)
  .handleAction(updateLoadingNeloCards, setLoadingNeloCards)
  .handleAction(updateNeloCards, setNeloCards);
