import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SignupState } from '../ducks/signup';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  button: {
    marginTop: spacing.spacing2x
  }
});

interface StateProps {
  firstName: string;
  paternalLastName: string;
  maternalLastName: string;
  dateOfBirth: string;
  sex: string;
  placeOfBirth: string;
  isInternationalBirthEntity: boolean;
}

function VerifyPersonalInfo(props: StateProps): ReactElement {
  const classes = useStyles();

  const history = useHistory();
  const path = props.isInternationalBirthEntity ? '/signup/country-entry' : '/signup/occupation-entry';

  const fields = [
    {
      label: i18next.t('signup.verifyPersonalInfo.firstName.label'),
      value: props.firstName
    },
    {
      label: i18next.t('signup.verifyPersonalInfo.paternalLastName.label'),
      value: props.paternalLastName
    },
    {
      label: i18next.t('signup.verifyPersonalInfo.maternalLastName.label'),
      value: props.maternalLastName
    },
    {
      label: i18next.t('signup.verifyPersonalInfo.dateOfBirth.label'),
      value: props.dateOfBirth
    },
    {
      label: i18next.t('signup.verifyPersonalInfo.sex.label'),
      value: props.sex
    },
    {
      label: i18next.t('signup.verifyPersonalInfo.placeOfBirth.label'),
      value: props.placeOfBirth
    }
  ];
  return (
    <NeloBrandWrapper title={i18next.t('signup.verifyPersonalInfo.title')} stepperIndex={1}>
      <Grid container direction="column">
        {fields.map((field, i) => {
          return (
            <Grid key={i} direction="row" container justifyContent="space-between">
              <Typography color="secondary" variant="body2">
                {field.label}
              </Typography>
              <Typography>{field.value}</Typography>
            </Grid>
          );
        })}
        <NeloButton
          text={i18next.t('signup.verifyPersonalInfo.submitButtonText')}
          className={classes.button}
          onClick={(): void => history.push(path)}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapStateToProps = ({ signup }: { signup: SignupState }): StateProps => ({
  firstName: signup.validateCurpResponse?.firstName || '',
  paternalLastName: signup.validateCurpResponse?.paternalLastName || '',
  maternalLastName: signup.validateCurpResponse?.maternalLastName || '',
  dateOfBirth: signup.validateCurpResponse?.dateOfBirth || '',
  sex: signup.validateCurpResponse?.sex || '',
  placeOfBirth: signup.validateCurpResponse?.birthEntity || '',
  isInternationalBirthEntity: signup.validateCurpResponse?.isInternationalBirthEntity || false
});

export default connect(mapStateToProps)(VerifyPersonalInfo);
