import TextField from '@material-ui/core/TextField';
import { History } from 'history';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { ApplicationProps, ApplicationState } from '../ducks/application';
import { AuthState, submitPin, updatePin } from '../ducks/auth';
import i18next from '../localization/i18n';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';
import { useStyles } from './PhoneEntry';

interface DispatchProps {
  submitPin: (history: History) => void;
  updatePin: (pin: string) => void;
}

interface StateProps extends ApplicationProps {
  pin: string | null;
}

function PinEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  return (
    <NeloBrandWrapper title={i18next.t('pinEntry.title')} subtitle={i18next.t('pinEntry.subtitle')}>
      <TextField
        required
        id="standard-basic"
        className={classes.textEntry}
        label={i18next.t('pinEntry.pin.label')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updatePin(e.target.value)}
        value={props.pin}
        type="password"
        inputProps={{ maxLength: 4 }}
      />
      <NeloButton
        isLoading={props.isLoading}
        text={i18next.t('pinEntry.pin.buttonText')}
        className={classes.button}
        onClick={(): void => props.submitPin(history)}
      />
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  submitPin: (history: History): PayloadAction<string, History> => submitPin(history),
  updatePin: (pin: string): PayloadAction<string, string> => updatePin(pin)
};

const mapStateToProps = ({ auth, application }: { auth: AuthState; application: ApplicationState }): StateProps => ({
  pin: auth.pin,
  isLoading: application.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(PinEntry);
