import { LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ReactElement } from 'react';

import { spacing } from '../../styling';

export const useStyles = makeStyles({
  progress: {
    height: spacing.spacing1x,
    width: '80%'
  },
  debitCardRow: {
    height: spacing.spacing10x,
    marginTop: spacing.spacing2x,
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .1)',
    width: '100%',
    textTransform: 'none'
  }
});

export const LoadingCardSection = (): ReactElement => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      direction="row"
      className={classes.debitCardRow}
    >
      <LinearProgress className={classes.progress} />
    </Grid>
  );
};
