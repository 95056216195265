import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { emitAnalyticEventAction } from '../ducks/analytics';
import { IdentityVerificationStatus } from '../interfaces/nelo-api/IdentityVerificationStatus';
import { LoanApplicationState } from '../interfaces/nelo-api/LoanApplicationState';
import { colors, spacing } from '../styling';

export const useStyles = makeStyles({
  loader: {
    padding: spacing.spacing2x,
    marginBottom: spacing.spacing1x,
    borderRadius: 4,
    borderColor: colors.primary,
    backgroundColor: `${colors.primary}1A`
  },
  statusMessage: {
    fontWeight: 'bold',
    marginBottom: spacing.spacing2x
  }
});

interface LoadingBarProps {
  message: string;
  maxLoadingTime: number; // passed in seconds
  finishedLoading: () => void;
  listeningTo: LoanApplicationState | IdentityVerificationStatus;
  stepIdentifier: string;
}

const FINAL_DECISION_STATES = ['APPROVED', 'VERIFIED', 'REJECTED'];

function LoadingBar({
  message,
  maxLoadingTime,
  finishedLoading,
  listeningTo,
  stepIdentifier
}: LoadingBarProps): ReactElement {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadingValue, setLoadingValue] = useState(0);
  const [intervalIsActive, setIntervalState] = useState(true);
  const initialState = useRef(listeningTo);
  let updateProgressInterval: ReturnType<typeof setInterval>;
  const analyticsUuid = stepIdentifier || '';

  const totalTime = maxLoadingTime * 1000; // convert to milliseconds
  let currentInterval = 0;

  const getRandomNumber = (min: number, max: number): number => {
    return Math.random() * (max - min) + min;
  };

  const updateProgress = (): void => {
    const newLoadingValue = getRandomNumber(currentInterval, currentInterval + 10);
    currentInterval += 10;
    const willUpdateProgress = getRandomNumber(0, 1) > 0.3;
    willUpdateProgress && intervalIsActive && setLoadingValue(newLoadingValue);
    const stopProgress = currentInterval == 100;
    stopProgress && clearInterval(updateProgressInterval);
  };

  useEffect(() => {
    updateProgressInterval = setInterval(() => {
      updateProgress();
    }, totalTime / 10);
    return (): void => {
      clearInterval(updateProgressInterval);
    };
  }, [intervalIsActive]);

  useEffect(() => {
    dispatch(
      emitAnalyticEventAction({
        name: 'LOAN_OPTIONS_LOADING_TIME',
        params: { startTime: new Date().getTime().toString(), eventUuid: analyticsUuid }
      })
    );
    return (): void => {
      dispatch(
        emitAnalyticEventAction({
          name: 'LOAN_OPTIONS_LOADING_TIME',
          params: { endTime: new Date().getTime().toString(), eventUuid: analyticsUuid }
        })
      );
    };
  }, []);

  useEffect(() => {
    if (listeningTo !== initialState.current || (listeningTo !== null && FINAL_DECISION_STATES.includes(listeningTo))) {
      setIntervalState(false);
      setLoadingValue(100);
      // If our value hasn't changed we are already arrived at a final decision state
      // so we don't want to wait for the loading bar to completely load
      listeningTo === initialState.current && finishedLoading();
      setTimeout(() => {
        finishedLoading();
      }, 2000);
    }
  }, [listeningTo]);

  return (
    <Grid>
      <LinearProgress value={loadingValue} className={classes.loader} variant="determinate" />
      <Typography variant="subtitle1" className={classes.statusMessage}>
        {message}
      </Typography>
    </Grid>
  );
}

export default LoadingBar;
