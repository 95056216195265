import i18n from '../localization/i18n';

interface Occupation {
  value: string;
  label: string;
}

const Occupations: Occupation[] = [
  { value: 'CONTRACTOR', label: i18n.t('occupation.contractor') },
  { value: 'PRIVATE_EMPLOYEE', label: i18n.t('occupation.privateEmployee') },
  { value: 'PUBLIC_EMPLOYEE', label: i18n.t('occupation.publicEmployee') },
  { value: 'SELF_EMPLOYED', label: i18n.t('occupation.selfEmployed') },
  { value: 'STUDENT', label: i18n.t('occupation.student') },
  { value: 'FINANCE_SECTOR', label: i18n.t('occupation.financeSector') },
  { value: 'RETIRED', label: i18n.t('occupation.retired') },
  { value: 'PROFESSIONAL', label: i18n.t('occupation.professional') },
  { value: 'HOMEMAKER', label: i18n.t('occupation.homemaker') },
  { value: 'PENSIONER', label: i18n.t('occupation.pensioner') },
  { value: 'PRIMARY_SECTOR', label: i18n.t('occupation.primarySector') },
  { value: 'SECONDARY_SECTOR', label: i18n.t('occupation.secondarySector') },
  { value: 'TERTIARY_SECTOR', label: i18n.t('occupation.tertiarySector') },
  { value: 'UNEMPLOYED', label: i18n.t('occupation.unemployed') },
  { value: 'ENTREPRENEUR', label: i18n.t('occupation.entrepreneur') },
  { value: 'MERCHANT', label: i18n.t('occupation.merchant') },
  { value: 'INDEPENDENT', label: i18n.t('occupation.independent') },
  { value: 'OTHER', label: i18n.t('occupation.other') }
];

export default Occupations;
