import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import React, { ReactElement, useEffect } from 'react';

import { spacing } from '../styling';

export const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    fontWeight: 'bold',
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x
  }
});

interface NeloButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  text: string;
  onClick: () => void;
  className: string;
}

function NeloButton(props: NeloButtonProps): ReactElement {
  const classes = useStyles();

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if (e.code === 'Enter') {
        props.onClick();
      }
    };
    document.addEventListener('keydown', listener);
    return (): void => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <LoadingButton
      pending={props.isLoading}
      disabled={props.disabled}
      onClick={props.onClick}
      variant="contained"
      disableElevation
      className={`${classes.button} ${props.className}`}
    >
      {props.text}
    </LoadingButton>
  );
}

export default NeloButton;
