import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';

import i18next from '../localization/i18n';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';

export const useStyles = makeStyles({
  questionText: {
    fontWeight: 'bold'
  },
  button: {
    marginTop: spacing.spacing3x,
    marginBottom: spacing.spacing3x,
    paddingLeft: spacing.spacing4x,
    paddingRight: spacing.spacing4x,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x
  }
});

function ErrorPage(): ReactElement {
  const classes = useStyles();

  return (
    <NeloBrandWrapper title={i18next.t('errorPage.title')}>
      <Grid container direction="column">
        <Typography variant="body1" className={classes.questionText}>
          {i18next.t('errorPage.body')}
        </Typography>
      </Grid>
    </NeloBrandWrapper>
  );
}

export default ErrorPage;
