import { datadogRum } from '@datadog/browser-rum';
import { Store } from 'redux';

import { AnalyticEventName } from '../analytics/AnalyticEventName';
import {
  ADDRESS,
  AUTOCOMPLETE_ADDRESS,
  CARDS,
  CONFIRM_LOAN,
  CONFIRM_MOBILE_VERIFICATION,
  CREATE_LOAN,
  CREATE_ONFIDO_APPLICANT,
  CREATE_ONFIDO_CHECK,
  CREATE_TRANSACTION_TOKEN,
  LOGIN,
  makeUrl,
  SEND_MOBILE_VERIFICATION,
  SIGNUP,
  VALIDATE_CURP
} from '../constants/urls';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationState, logout } from '../ducks/application';
import { CreateCardRequest } from '../ducks/downPayment';
import * as ApiErrors from '../errors/NeloApi';
import ErrorDetails from '../interfaces/nelo-api/ErrorDetails';
import { ConfirmLoanRequest, CreateBnplOrderLoanRequest } from '../interfaces/nelo-api/Loan';
import { LoginRequest } from '../interfaces/nelo-api/Login';
import { SignupRequest } from '../interfaces/nelo-api/Signup';
import { ConfirmVerificationCodeRequest, CreateVerificationCodeRequest } from '../interfaces/nelo-api/VerificationCode';
import { getAuthorizationHeader, getHeaders } from '../util/Headers';
import { getUrlWithQueryString } from '../util/Url';

interface AccessTokenHolder {
  accessToken: string;
}

class NeloApiClient {
  store: Store;

  private getAccessToken(): string {
    const auth = this.store.getState().auth as AccessTokenHolder;
    return auth.accessToken;
  }

  private getSessionId(): string {
    const application = this.store.getState().application as ApplicationState;
    return application.sessionId;
  }

  private getAuthorizationHeader(): string | null {
    const accessToken = this.getAccessToken();
    return getAuthorizationHeader(accessToken);
  }

  private async getHeaders(isJsonContent: boolean): Promise<Headers> {
    const headers = await getHeaders();
    const authHeader = this.getAuthorizationHeader();
    const sessionId = this.getSessionId();
    if (authHeader) {
      headers.set('Authorization', authHeader);
    }
    if (isJsonContent) {
      headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    if (sessionId) {
      headers.set('session-id', sessionId);
    }

    return headers;
  }

  private emitAnalyticEvent(event: AnalyticEventName, status?: number, message?: string): void {
    this.store.dispatch(
      emitAnalyticEventAction({
        name: event,
        params: { status: (status && status.toString()) || '', message: message || '' }
      })
    );
  }

  private async handleError(response: Response): Promise<void> {
    const rawBody = await response.text();
    let parsedBody: ErrorDetails | undefined;
    if (rawBody) {
      try {
        parsedBody = JSON.parse(rawBody);
        datadogRum.addError(
          'API Error',
          {
            errorStatus: response.status,
            data: parsedBody
          },
          'network'
        );
      } catch (error) {
        console.warn('Error response body is invalid JSON', rawBody);
        datadogRum.addError(
          'Unprocessed API Error',
          {
            message: rawBody
          },
          'network'
        );
      }
    }
    switch (response.status) {
      case 0:
        throw new ApiErrors.NoResponseError(response);
      case 400:
        if (parsedBody?.insufficientFunds) {
          throw new ApiErrors.InsufficientFundsError(response, parsedBody);
        }
        if (parsedBody?.minAllowedAmount) {
          throw new ApiErrors.LoanAmountError(response, parsedBody);
        }
        throw new ApiErrors.BadInputError(response, parsedBody);
      case 401:
        this.store.dispatch(logout());
        throw new ApiErrors.UnauthenticatedError(response, parsedBody);
      case 403:
        if (parsedBody?.fraud) {
          throw new ApiErrors.FraudError(response, parsedBody);
        } else if (parsedBody?.signupBlocked) {
          throw new ApiErrors.SignupBlockedError(response, parsedBody);
        } else if (parsedBody?.loanRejectionReason) {
          throw new ApiErrors.LoanRejectionError(response, parsedBody);
        } else if (parsedBody?.identityStatus) {
          throw new ApiErrors.IdentityNotVerifiedError(response, parsedBody);
        }
        throw new ApiErrors.ForbiddenError(response, parsedBody);
      case 422:
        if (parsedBody?.message) {
          throw new ApiErrors.UnprocessableEntityError(response, parsedBody);
        }
        throw new ApiErrors.GenericError(response, parsedBody);
      case 429:
        if (parsedBody?.message) {
          throw new ApiErrors.TooManyRequestsError(response, parsedBody);
        }
        throw new ApiErrors.GenericError(response, parsedBody);
      case 500:
        throw new ApiErrors.InternalServerError(response);
      default:
        throw new ApiErrors.GenericError(response, parsedBody);
    }
  }

  private async makeRequestInternal(
    method: string,
    url: string,
    body: string | null,
    isJsonContent: boolean,
    event?: AnalyticEventName
  ): Promise<Response> {
    const options: RequestInit = {
      method,
      mode: 'cors',
      headers: await this.getHeaders(isJsonContent)
    };
    options.body = body;
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        await this.handleError(response);
      }
      event && this.emitAnalyticEvent(event, response.status);
      return response;
    } catch (error) {
      event && this.emitAnalyticEvent(event, error.response && error.response.status, error.message);
      if (error.message === 'Network request failed') {
        throw new ApiErrors.NoResponseError();
      } else {
        throw error;
      }
    }
  }

  private async post(url: string, data?: Record<string, any>, event?: AnalyticEventName): Promise<Response> {
    return this.makeRequestInternal('POST', url, (data && JSON.stringify(data)) || null, true, event);
  }

  private async put(url: string, data?: Record<string, any>, event?: AnalyticEventName): Promise<Response> {
    return this.makeRequestInternal('PUT', url, (data && JSON.stringify(data)) || null, true, event);
  }

  private async delete(url: string, event?: AnalyticEventName): Promise<Response> {
    return this.makeRequestInternal('DELETE', url, null, false, event);
  }

  private async get(baseUrl: string, data?: Record<string, string>, event?: AnalyticEventName): Promise<Response> {
    const url = getUrlWithQueryString(baseUrl, data);
    return this.makeRequestInternal('GET', url, null, false, event);
  }

  public createMobileVerification(requestBody: CreateVerificationCodeRequest): Promise<Response> {
    return this.post(SEND_MOBILE_VERIFICATION, requestBody, 'CODE_SEND_REQUEST');
  }

  public confirmMobileVerification(requestBody: ConfirmVerificationCodeRequest): Promise<Response> {
    return this.put(CONFIRM_MOBILE_VERIFICATION, requestBody, 'CODE_CONFIRM_REQUEST');
  }

  public loginWithPinCode(requestBody: LoginRequest): Promise<Response> {
    return this.post(LOGIN, requestBody, 'LOGIN_PIN_REQUEST');
  }

  public getLoanOptions(checkoutToken: string): Promise<Response> {
    return this.get(makeUrl(`order/payment-options/${checkoutToken}`), undefined, 'GET_LOAN_OPTIONS_REQUEST');
  }

  public createBerbixTransaction(): Promise<Response> {
    return this.post(CREATE_TRANSACTION_TOKEN, {}, 'CREATE_TRANSACTION_TOKEN');
  }

  public createOnfidoApplicant(): Promise<Response> {
    return this.post(CREATE_ONFIDO_APPLICANT, {}, 'CREATE_ONFIDO_APPLICANT');
  }

  public createOnfidoCheck(): Promise<Response> {
    return this.post(CREATE_ONFIDO_CHECK, {}, 'CREATE_ONFIDO_CHECK');
  }

  public createLoan(requestBody: CreateBnplOrderLoanRequest): Promise<Response> {
    return this.post(CREATE_LOAN, requestBody, 'CREATE_BNPL_LOAN_REQUEST');
  }

  public confirmLoan(requestBody: ConfirmLoanRequest): Promise<Response> {
    return this.put(CONFIRM_LOAN, requestBody, 'CONFIRM_BNPL_LOAN_REQUEST');
  }

  public validateCurp(curp: string): Promise<Response> {
    return this.get(VALIDATE_CURP, { curp: curp }, 'CURP_VALIDATE_REQUEST_V3');
  }

  public autocompleteAddress(input: string, session: string): Promise<Response> {
    return this.get(AUTOCOMPLETE_ADDRESS, { input, session }, 'ADDRESS_AUTOCOMPLETE_REQUEST');
  }

  public getAddress(placeId: string, session: string): Promise<Response> {
    return this.get(ADDRESS, { placeId, session }, 'ADDRESS_REQUEST');
  }

  public signup(requestBody: SignupRequest): Promise<Response> {
    return this.post(SIGNUP, requestBody, 'SIGNUP_V4_REQUEST');
  }

  public registerStore(store: Store): void {
    this.store = store;
  }

  public getCards(): Promise<Response> {
    return this.get(CARDS, undefined, 'GET_CARDS');
  }

  public createCard(requestBody: CreateCardRequest): Promise<Response> {
    return this.post(CARDS, requestBody, 'CREATE_CARD');
  }

  public voidCheckout(orderUuid: string): Promise<Response> {
    return this.post(makeUrl(`checkout/${orderUuid}/notify-void`), undefined, 'VOID_CHECKOUT');
  }
}

export default new NeloApiClient();
