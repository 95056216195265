import { ReactElement, useState } from 'react';

import FourOhFour from '../../components/FourOhFour';
import NeloBrandWrapper from '../../components/NeloBrandWrapper';
import { isProd } from '../../util/Environment';
import { generateDevCheckout } from '../util/generateDevCheckoutToken';

const SUBTITLE_LOADING = 'Generating the checkout token';
const SUBTITLE_ERROR = 'Oops, there was an error with your request';
const DESCRIPTION_LOADING = 'Please wait, you will be redirected to the dev checkout flow once the process is complete';
const DESCRIPTION_ERROR = 'Check the console for more details';
const TITLE = 'Loading Dev Checkout...';
function DevCheckout(): ReactElement {
  const prod = isProd();
  const [subtitle, setSubtitle] = useState(SUBTITLE_LOADING);
  const [description, setDescription] = useState(DESCRIPTION_LOADING);

  const displayFetchError = (subtitle: string, description: string): void => {
    setSubtitle(subtitle);
    setDescription(description);
  };

  if (!prod) {
    const devCheckout = generateDevCheckout();
    devCheckout.then(redirectUrl => {
      if (redirectUrl !== undefined) {
        window.location.replace(redirectUrl);
      } else {
        displayFetchError(SUBTITLE_ERROR, DESCRIPTION_ERROR);
      }
    });
  }

  return (
    <div>
      {prod ? <FourOhFour /> : <NeloBrandWrapper title={TITLE} subtitle={subtitle} description={description} />}
    </div>
  );
}

export default DevCheckout;
