export type IdentityVerificationStatus =
  | 'UNVERIFIED'
  | 'SUBMITTED_VERIFICATION'
  | 'PROCESSING'
  | 'VERIFIED'
  | 'REVIEW_NEEDED'
  | 'REJECTED'
  | 'EXPIRED'
  | 'DOCUMENT_MISMATCH_STATUS';

export const pendingIdentityVerificationStates: IdentityVerificationStatus[] = [
  'SUBMITTED_VERIFICATION',
  'PROCESSING',
  'VERIFIED',
  'REVIEW_NEEDED'
];

export const failedIdentityVerificationStates: IdentityVerificationStatus[] = [
  'UNVERIFIED',
  'EXPIRED',
  'DOCUMENT_MISMATCH_STATUS',
  'REJECTED'
];
