import Autocomplete from '@material-ui/core/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { History } from 'history';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { MexicanState, MexicanStates } from '../constants/mexicanstates';
import { ApplicationProps, ApplicationState } from '../ducks/application';
import {
  SignupState,
  submitSignup,
  updateBuildingNumber,
  updateCity,
  updateColony,
  updateInteriorNumber,
  updatePlaceId,
  updatePostalCode,
  updateState,
  updateStreet
} from '../ducks/signup';
import { SearchAddress } from '../interfaces/nelo-api/AddressAutocomplete';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import { isEmpty } from '../util/isEmpty';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  textEntry: {
    marginTop: spacing.spacing2x,
    marginBottom: spacing.spacing2x
  },
  button: {
    marginTop: spacing.spacing2x
  },
  smallTextEntry: {
    width: '48%'
  }
});

interface SelectElement {
  value: unknown;
}

interface DispatchProps {
  updatePlaceId: (placeId: string) => void;
  updateStreet: (street: string) => void;
  updateBuildingNumber: (buildingNumber: string) => void;
  updateInteriorNumber: (interiorNumber: string) => void;
  updateColony: (colony: string) => void;
  updateCity: (city: string) => void;
  updateState: (state: string) => void;
  updatePostalCode: (postalCode: string) => void;
  submitSignup: (history: History) => void;
}

interface StateProps extends ApplicationProps {
  street: string;
  buildingNumber: string;
  interiorNumber: string;
  colony: string;
  city: string;
  state: MexicanState | '';
  postalCode: string;
  autocompleteAddresses: SearchAddress[];
}

function AddressEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = useStyles();

  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  return (
    <NeloBrandWrapper title={i18next.t('signup.addressEntry.title')} stepperIndex={3}>
      <Grid container direction="column">
        <Autocomplete
          id="combo-box-demo"
          freeSolo={true}
          inputValue={props.street}
          options={props.autocompleteAddresses}
          getOptionLabel={(option): string => option.description}
          onChange={(e, newValue): void | null | '' => {
            if (!newValue || typeof newValue === 'string') {
              return;
            }
            props.updatePlaceId(newValue.placeId);
          }}
          className={classes.textEntry}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              required
              error={hasSubmitted && isEmpty(props.street)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateStreet(e.target.value)}
              label={i18next.t('signup.addressEntry.street.label')}
            />
          )}
        />
        <Grid container justifyContent="space-between" direction="row">
          <TextField
            required
            error={hasSubmitted && isEmpty(props.buildingNumber)}
            className={`${classes.textEntry} ${classes.smallTextEntry}`}
            id="standard-basic"
            label={i18next.t('signup.addressEntry.buildingNumber.label')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateBuildingNumber(e.target.value)}
            value={props.buildingNumber}
          />
          <TextField
            className={`${classes.textEntry} ${classes.smallTextEntry}`}
            id="standard-basic"
            label={i18next.t('signup.addressEntry.interiorNumber.label')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateInteriorNumber(e.target.value)}
            value={props.interiorNumber}
          />
        </Grid>
        <TextField
          required
          error={hasSubmitted && isEmpty(props.colony)}
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('signup.addressEntry.colony.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateColony(e.target.value)}
          value={props.colony}
        />
        <TextField
          required
          error={hasSubmitted && isEmpty(props.city)}
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('signup.addressEntry.city.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateCity(e.target.value)}
          value={props.city}
        />
        <FormControl required variant="outlined" className={classes.textEntry}>
          <InputLabel htmlFor="address-state-entry">{i18next.t('signup.addressEntry.state.label')}</InputLabel>
          <Select
            id="standard-basic"
            native
            error={hasSubmitted && isEmpty(props.state)}
            value={props.state}
            onChange={(e: React.ChangeEvent<SelectElement>): void => props.updateState(e.target.value as string)}
            label={i18next.t('signup.addressEntry.state.label')}
            inputProps={{
              name: 'address-state',
              id: 'address-state-entry'
            }}
          >
            <option aria-label="None" value="" />
            {MexicanStates.map((state: MexicanState) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          error={hasSubmitted && isEmpty(props.postalCode)}
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('signup.addressEntry.postalCode.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updatePostalCode(e.target.value)}
          value={props.postalCode}
        />
        <NeloButton
          isLoading={props.isLoading}
          text={i18next.t('signup.addressEntry.submitButtonText')}
          className={classes.button}
          onClick={(): void => {
            setHasSubmitted(true);
            if (
              !isEmpty(props.street) &&
              !isEmpty(props.buildingNumber) &&
              !isEmpty(props.city) &&
              !isEmpty(props.state) &&
              !isEmpty(props.postalCode) &&
              !isEmpty(props.colony)
            ) {
              props.submitSignup(history);
            }
          }}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  submitSignup: (history: History): PayloadAction<string, History> => submitSignup(history),
  updatePlaceId: (placeId: string): PayloadAction<string, string> => updatePlaceId(placeId),
  updateStreet: (street: string): PayloadAction<string, string> => updateStreet(street),
  updateBuildingNumber: (buildingNumber: string): PayloadAction<string, string> => updateBuildingNumber(buildingNumber),
  updateInteriorNumber: (interiorNumber: string): PayloadAction<string, string> => updateInteriorNumber(interiorNumber),
  updateColony: (colony: string): PayloadAction<string, string> => updateColony(colony),
  updateCity: (city: string): PayloadAction<string, string> => updateCity(city),
  updateState: (state: string): PayloadAction<string, string> => updateState(state),
  updatePostalCode: (postalCode: string): PayloadAction<string, string> => updatePostalCode(postalCode)
};

const mapStateToProps = ({
  signup,
  application
}: {
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  street: signup.address.street,
  buildingNumber: signup.address.buildingNumber,
  interiorNumber: signup.address.interiorNumber,
  colony: signup.address.colony,
  city: signup.address.city,
  state: signup.address.state,
  postalCode: signup.address.postalCode,
  autocompleteAddresses: signup.autocompleteAddresses,
  isLoading: application.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressEntry);
