import { call, put, SagaGenerator, takeEvery } from 'typed-redux-saga';
import { createAction, PayloadAction } from 'typesafe-actions';

import { Action as ServerAction, ActionType as ServerActionType } from '../interfaces/nelo-api/Action';
import { emitAnalyticEventAction } from './analytics';

type ServerActionHandler = (payload: string | null) => SagaGenerator<void>;

export const clickedServerDrivenCta = createAction('serverDrivenCta/CLICKED_CTA')<ServerAction>();

function* openUrlExternally(payload: string | null): SagaGenerator<void> {
  if (payload === null) {
    return;
  }

  window.open(payload, '_blank');
}

function UnhandledActionReporter(type: ServerActionType): ServerActionHandler {
  return function* reportUnhandledType(): SagaGenerator<void> {
    console.warn('Unhandled action type', type);
    yield* put(
      emitAnalyticEventAction({
        name: 'UNHANDLED_SERVER_DRIVEN_ACTION_TYPE',
        params: { type: type?.toString() ?? '' }
      })
    );
  };
}

const getHandler = (type: ServerActionType): ServerActionHandler => {
  switch (type) {
    case 'OPEN_URL':
      return openUrlExternally;
    default:
      return UnhandledActionReporter(type);
  }
};

function* handleCtaCick(action: PayloadAction<string, ServerAction>): SagaGenerator<void> {
  const { type, payload } = action.payload;
  const handler: ServerActionHandler = yield* call(getHandler, type);
  yield* call(handler, payload);
}

export function* serverDrivenCtaSaga(): SagaGenerator<void> {
  yield* takeEvery(clickedServerDrivenCta, handleCtaCick);
}
