import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../ducks/application';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import Success from '../svg/success.svg';
import NeloBrandWrapper from './NeloBrandWrapper';

export const useStyles = makeStyles({
  card: {
    marginTop: spacing.spacing8x,
    marginBottom: spacing.spacing1x,
    display: 'flex'
  },
  successImage: {
    marginTop: spacing.spacing4x
  },
  cardContent: {
    flex: 1,
    alignItems: 'center'
  },
  orderReference: {
    textAlign: 'center'
  }
});

interface StateProps {
  orderReference: string;
}

const LoanSuccessInStore = (props: StateProps): ReactElement => {
  const { orderReference } = props;
  const classes = useStyles();

  return (
    <NeloBrandWrapper title={i18next.t('checkout.success.title')} subtitle={i18next.t('checkout.success.description')}>
      <img className={classes.successImage} src={Success} />
      <Card className={classes.card}>
        <Grid direction="row" container justifyContent="space-between">
          <CardContent className={classes.cardContent}>
            <Typography className={classes.orderReference} variant="h6">
              {i18next.t('checkout.success.reference', { orderReference })}
            </Typography>
          </CardContent>
        </Grid>
      </Card>
    </NeloBrandWrapper>
  );
};

const mapStateToProps = ({ application }: { application: ApplicationState }): StateProps => ({
  orderReference: application.orderReference
});

export default connect(mapStateToProps)(LoanSuccessInStore);
