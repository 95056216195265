import { isProd } from '../util/Environment';

const API_HOST = isProd() ? 'api-v2' : 'api-v2-dev';

const BASE_URL = `https://${API_HOST}.nelo.co/`;

export function makeUrl(path: string): string {
  return `${BASE_URL}${path}`;
}

export const SEND_MOBILE_VERIFICATION = makeUrl('code/send');
export const CONFIRM_MOBILE_VERIFICATION = makeUrl('code/confirm');
export const LOGIN = makeUrl('login/pin');
export const CREATE_LOAN = makeUrl('bnpl-order-loan');
export const CONFIRM_LOAN = makeUrl('bnpl-order-loan');
export const VALIDATE_CURP = makeUrl('v3/curp/validate');
export const AUTOCOMPLETE_ADDRESS = makeUrl('address/autocomplete');
export const CREATE_TRANSACTION_TOKEN = makeUrl('berbix/create-transaction');
export const CREATE_ONFIDO_APPLICANT = makeUrl('onfido/create-applicant');
export const CREATE_ONFIDO_CHECK = makeUrl('onfido/create-check');
export const SIGNUP = makeUrl('v4/signup');
export const ADDRESS = makeUrl('address');
export const CARDS = makeUrl('cards');
