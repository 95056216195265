import { Button, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { AnalyticEventName } from '../../analytics/AnalyticEventName';
import { NeloCard } from '../../ducks/downPayment';
import i18next from '../../localization/i18n';
import { colors, spacing } from '../../styling';
import { CardDisplay } from './CardDisplay';
import { LoadingCardSection } from './LoadingCardSection';

export const useStyles = makeStyles({
  cardSection: {
    borderBottom: `2px solid ${colors.accent}`,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing4x
  },
  paymentPlanText: {
    color: colors.primary,
    fontWeight: 'bold'
  },
  arrowForwardIcon: {
    color: colors.primary
  },
  debitCardRow: {
    height: spacing.spacing10x,
    marginTop: spacing.spacing2x,
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .1)',
    width: '100%',
    textTransform: 'none'
  },
  cardNotSelectedError: {
    height: spacing.spacing10x,
    marginTop: spacing.spacing2x,
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .1)',
    width: '100%',
    textTransform: 'none',
    borderColor: 'red',
    borderWidth: '0.5px',
    borderStyle: 'solid'
  },
  cardText: {
    marginLeft: spacing.spacingOneAndHalfx,
    color: 'black'
  },
  addCard: {
    marginLeft: spacing.spacing2x,
    backgroundColor: '#E8E8E8'
  },
  iconLogo: {
    marginLeft: spacing.spacing4x,
    height: spacing.spacing6x,
    width: spacing.spacing4x,
    color: 'black'
  }
});

interface AddCardButtonProps {
  onClick: () => void;
  displayErrorCardNotSelected?: boolean;
}

interface CardSectionProps {
  isLoadingCards: boolean;
  cards: NeloCard[];
  errorMessage: string;
  onCardSelect: (card: NeloCard) => void;
  sendCardAnalyticsEvent: (eventName: AnalyticEventName) => void;
}

interface ToggleCardsCtaProps {
  displayingCards: boolean;
  toggleCards: () => void;
}

const AddCardButton = (props: AddCardButtonProps): ReactElement => {
  const classes = useStyles();
  const { displayErrorCardNotSelected } = props;

  return (
    <Button
      onClick={props.onClick}
      className={displayErrorCardNotSelected ? classes.cardNotSelectedError : classes.debitCardRow}
    >
      <Grid container alignContent="center" alignItems="center" direction="row">
        <IconButton className={classes.addCard}>
          <AddIcon />
        </IconButton>
        <Typography className={classes.cardText} variant="body2">
          {i18next.t('downPayment.card.new')}
        </Typography>
      </Grid>
    </Button>
  );
};

const ToggleCardsCta = (props: ToggleCardsCtaProps): ReactElement => {
  const classes = useStyles();
  const { displayingCards, toggleCards } = props;

  return (
    <Typography className={classes.paymentPlanText}>
      {displayingCards ? i18next.t('downPayment.card.close') : i18next.t('downPayment.card.change')}
      <IconButton onClick={toggleCards}>
        {displayingCards ? (
          <ArrowUpwardIcon className={classes.arrowForwardIcon} />
        ) : (
          <ArrowDownwardIcon className={classes.arrowForwardIcon} />
        )}
      </IconButton>
    </Typography>
  );
};

export const CardSection = (props: CardSectionProps): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const [seeAllCards, setSeeAllCards] = useState(false);
  const [selectedCardUuid, setSelectedCardUuid] = useState('');

  const { isLoadingCards, cards, onCardSelect, sendCardAnalyticsEvent, errorMessage } = props;
  const { uuid }: { uuid: string } = useParams();

  const goToDebitCardForm = (): void => {
    sendCardAnalyticsEvent('CARD_GOING_TO_FORM');
    history.push(`/down-payment/${uuid}`);
  };

  const selectCard = (uuid: string): void => {
    if (uuid !== selectedCardUuid) {
      const card = cards.find(card => card.uuid == uuid);
      if (card) {
        const cardIndex = cards.indexOf(card);
        moveCard(cardIndex, 0);
        toggleCardDisplays();
        setSelectedCardUuid(uuid);
        onCardSelect(card);
      }
    }
  };

  const toggleCardDisplays = (): void => {
    setSeeAllCards(!seeAllCards);
  };

  const moveCard = (fromIndex: number, toIndex: number): void => {
    const element = cards[fromIndex];
    cards.splice(fromIndex, 1);
    cards.splice(toIndex, 0, element);
  };

  useEffect((): void => {
    if (cards.length > 0) {
      const lastCardIndex = cards.length - 1;
      const lastCardAdded = cards[lastCardIndex];
      setSelectedCardUuid(lastCardAdded.uuid);
      onCardSelect(lastCardAdded);
      moveCard(lastCardIndex, 0);
    }
  }, [cards]);

  return (
    <Grid className={classes.cardSection} direction="column">
      <Grid key={-2} direction="row" container justifyContent="space-between" alignItems="center" alignContent="center">
        <Typography variant="body2">{i18next.t('downPayment.card.paymentMethod')}</Typography>
        {cards.length > 0 && <ToggleCardsCta displayingCards={seeAllCards} toggleCards={toggleCardDisplays} />}
      </Grid>
      {isLoadingCards ? (
        <LoadingCardSection />
      ) : (
        <React.Fragment>
          {seeAllCards ? (
            cards
              .map(card => (
                <CardDisplay
                  selected={selectedCardUuid == card.uuid}
                  onClick={(): void => {
                    selectCard(card.uuid);
                  }}
                  brand={card.brand}
                  last4={card.lastFourDigits}
                />
              ))
              .concat([<AddCardButton onClick={goToDebitCardForm} />])
          ) : cards.length > 0 ? (
            <CardDisplay
              selected={selectedCardUuid == cards[0].uuid}
              onClick={(): void => {
                selectCard(cards[0].uuid);
              }}
              brand={cards[0].brand}
              last4={cards[0].lastFourDigits}
            />
          ) : (
            <AddCardButton displayErrorCardNotSelected={errorMessage !== ''} onClick={goToDebitCardForm} />
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};
