import { StrictEffect } from '@redux-saga/types';
import { all, call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { InvalidApplicationStateError } from '../errors/InvalidApplicationState';
import { LoanRejectionReason } from '../interfaces/nelo-api/ErrorDetails';
import { IdentityVerificationStatus } from '../interfaces/nelo-api/IdentityVerificationStatus';
import { ConfirmLoanRequest, ConfirmLoanResponse, LoanPreview, PaymentOption } from '../interfaces/nelo-api/Loan';
import { LoanApplicationState } from '../interfaces/nelo-api/LoanApplicationState';
import { PostMessagePayload } from '../interfaces/nelo-api/PostMessagePayload';
import i18next from '../localization/i18n';
import { isInIframe } from '../util/Modal';
import { doNeloApiRequestWithResponse, NeloApiRequestFn, safeCall } from '../util/neloApiRequest';
import { getState as getApplicationState, updateErrorMessage } from './application';

export const updatePaymentOptions = createAction('loan/UPDATE_PAYMENT_OPTIONS')<PaymentOptionsState>();
export const fetchLoanOptions = createAction('loan/FETCH_LOAN_OPTIONS')<string>();

export const createLoan = createAction('loan/CREATE_LOAN')<string>();
export const updateCurrentLoan = createAction('loan/UPDATE_CURRENT_LOAN')<CurrentLoanState>();

export const confirmLoan = createAction('loan/CONFIRM_LOAN')<ConfirmLoanRequest>();

export interface PaymentOptionsState {
  paymentOptions: PaymentOption[];
  loanApplicationState: LoanApplicationState;
  rejectionReason?: LoanRejectionReason;
  minAllowedAmount?: number;
  isUpfrontPaymentRequired: boolean;
}

export interface CurrentLoanState {
  loanPreview: LoanPreview | null;
  identityVerificationStatus: IdentityVerificationStatus;
  numberOfFailedVerificationAttempts: number;
}

export interface LoanState {
  paymentOptions: PaymentOption[];
  loanApplicationState: LoanApplicationState | null;
  identityVerificationStatus: IdentityVerificationStatus | null;
  numberOfFailedVerificationAttempts: number;
  loanPreview: LoanPreview | null;
  rejectionReason: LoanRejectionReason | null;
  minAllowedAmount: number | null;
}

export const initialState: LoanState = {
  paymentOptions: [],
  identityVerificationStatus: null,
  numberOfFailedVerificationAttempts: 0,
  loanPreview: null,
  loanApplicationState: null,
  rejectionReason: null,
  minAllowedAmount: null
};

const resetLoanState = (): LoanState => ({
  ...initialState
});

const resetLoanPreviewState = (state: LoanState): LoanState => ({
  ...state,
  loanPreview: null,
  identityVerificationStatus: null
});

const setPaymentOptions = (state: LoanState, action: PayloadAction<string, PaymentOptionsState>): LoanState => ({
  ...state,
  paymentOptions: action.payload.paymentOptions,
  loanApplicationState: action.payload.loanApplicationState,
  rejectionReason: action.payload.rejectionReason || null,
  minAllowedAmount: action.payload.minAllowedAmount || null
});

const setCurrentLoan = (state: LoanState, action: PayloadAction<string, CurrentLoanState>): LoanState => ({
  ...state,
  loanPreview: action.payload.loanPreview,
  identityVerificationStatus: action.payload.identityVerificationStatus,
  numberOfFailedVerificationAttempts: action.payload.numberOfFailedVerificationAttempts
});

export const getState = ({ loan }: { loan: LoanState }): LoanState => loan;

function* confirmLoanSaga(action: PayloadAction<string, ConfirmLoanRequest>): Generator<StrictEffect, void, void> {
  const loanUuid = action.payload;

  if (!loanUuid) {
    throw new InvalidApplicationStateError('Invalid loanUuid');
  }

  const request = action.payload;

  const responseBody = yield* call<NeloApiRequestFn<ConfirmLoanResponse>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.confirmLoan.bind(NeloApiClient, request)
  );
  const state = yield* select(getApplicationState);

  if (responseBody.isInStore) {
    action.payload.history.push('/in-store/success');
    return;
  }

  if (isInIframe()) {
    const payload: PostMessagePayload = {
      paymentUuid: state.orderUuid,
      reference: state.orderReference,
      type: 'CONFIRMED'
    };
    window.parent.postMessage(payload, '*');
  } else {
    if (!responseBody.redirectConfirmUrl) {
      yield* put(
        updateErrorMessage({
          errorMessage: i18next.t('loanSummary.error.invalidRedirectUrl')
        })
      );
    } else {
      window.location.href = responseBody.redirectConfirmUrl;
    }
  }
}

export function* loanSaga(): SagaGenerator<void> {
  yield* all([takeEvery(confirmLoan, safeCall, confirmLoanSaga)]);
}

export default createReducer(initialState)
  .handleAction(fetchLoanOptions, resetLoanState)
  .handleAction(createLoan, resetLoanPreviewState)
  .handleAction(updatePaymentOptions, setPaymentOptions)
  .handleAction(updateCurrentLoan, setCurrentLoan);
