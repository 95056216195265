import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { AnalyticEventName } from '../analytics/AnalyticEventName';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationError, ApplicationState, updateErrorMessage } from '../ducks/application';
import { CreateCard, createNeloCard, DownPaymentState, updateCardName, updatePostalCode } from '../ducks/downPayment';
import { LoanState } from '../ducks/loan';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import { isEmpty } from '../util/isEmpty';
import { StripeInputField } from '../util/StripeInput';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  textEntry: {
    marginTop: spacing.spacing2x,
    marginBottom: spacing.spacing2x
  },
  button: {
    marginTop: spacing.spacing2x
  },
  smallTextEntry: {
    width: '48%'
  },
  cardImage: {
    marginRight: spacing.spacing1x
  },
  terms: {
    fontSize: spacing.spacingOneAndHalfx,
    textDecoration: 'none'
  }
});

interface DispatchProps {
  updateCardName: (name: string) => void;
  updatePC: (pc: string) => void;
  setErrorMessage: (errorMessage: string) => void;
  createCard: (payload: CreateCard) => void;
  sendCardAnalyticsEvent: (name: AnalyticEventName) => void;
}

interface StateProps {
  fullName: string;
  postalCode: string;
  firstInstallmentAmount: string | undefined;
  loanUuid: string | undefined;
  isLoadingNetworkRequest: boolean;
}

const getPaymentAuthorizationTerms = (): ReactElement => {
  const classes = useStyles();

  const linkTagRegex = '<a([^>]+)>(.+?)</a>';
  const paymentAuthorizationHTML = i18next.t('downPayment.terms');
  const splitAuthorizationHtml = paymentAuthorizationHTML.match(linkTagRegex) as RegExpMatchArray;
  let termsUrl = splitAuthorizationHtml[1].split('=')[1];
  termsUrl = termsUrl.substring(1, termsUrl.length - 1);
  const termsAndConditions = (
    <a target="_blank" href={termsUrl} className={classes.terms}>
      {splitAuthorizationHtml[2]}
    </a>
  );
  paymentAuthorizationHTML.replace(splitAuthorizationHtml[0], '');
  const paymentTermsText = paymentAuthorizationHTML.split(splitAuthorizationHtml[0])[0];
  return (
    <p className={classes.terms}>
      {paymentTermsText}
      {termsAndConditions}
    </p>
  );
};

interface DownPaymentProps extends DispatchProps, StateProps {}

const DownPayment = (props: DownPaymentProps): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const elements = useElements();
  const stripe = useStripe();
  const [hasSubmitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { updateCardName, updatePC, setErrorMessage, createCard, sendCardAnalyticsEvent } = props;
  const { fullName, postalCode, firstInstallmentAmount, isLoadingNetworkRequest } = props;
  const { optionUuid }: { optionUuid: string } = useParams();

  const [state, setState] = useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: '',
    expiredError: '',
    cvcError: ''
  });

  const { cardNumberError, expiredError, cvcError } = state;

  const onElementChange = (field: string, errorField: string) => ({
    complete,
    error = { message: null }
  }: {
    complete: boolean;
    error?: { message: null | string };
  }): void => {
    setState({ ...state, [field]: complete, [errorField]: error.message });
  };

  const cardNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateCardName(e.target.value);
  };

  const postalCodeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updatePC(e.target.value);
  };

  const debitCardValuesHaveError = (): boolean => {
    return Boolean(cardNumberError) || Boolean(expiredError) || Boolean(cvcError);
  };

  const inputsAreValid = (): boolean => {
    return !debitCardValuesHaveError() && !isEmpty(postalCode) && !isEmpty(fullName);
  };

  const createPaymentMethod = (): void => {
    const cardNumber = elements?.getElement(CardNumberElement);
    setLoading(true);
    if (cardNumber && stripe) {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardNumber,
          billing_details: {
            name: fullName,
            address: {
              postal_code: postalCode
            }
          }
        })
        .then(result => {
          setLoading(false);
          if (result.paymentMethod) {
            const payload: CreateCard = {
              history,
              optionUuid,
              request: {
                cardToken: result.paymentMethod.id,
                lastFourDigits: result.paymentMethod.card?.last4,
                expirationMonth: result.paymentMethod.card?.exp_month?.toString(),
                expirationYear: result.paymentMethod.card?.exp_year?.toString(),
                brand: result.paymentMethod.card?.brand
              }
            };
            sendCardAnalyticsEvent('CARD_CREATION_SUCCESS');
            createCard(payload);
          } else {
            sendCardAnalyticsEvent('CARD_CREATION_ERROR');
            setErrorMessage(result.error.message || i18next.t('errors.genericError'));
          }
        });
    }
  };

  const submitCard = (): void => {
    if (!termsAccepted) {
      sendCardAnalyticsEvent('CARD_ERROR_TERMS_NOT_SELECTED');
      setErrorMessage(i18next.t('downPayment.terms.error'));
      return;
    }
    sendCardAnalyticsEvent('CARD_CREATION_ATTEMPT');
    setSubmitted(true);
    inputsAreValid() ? createPaymentMethod() : null;
  };

  const paymentAuthorizationTerms = getPaymentAuthorizationTerms();

  return (
    <NeloBrandWrapper
      title={i18next.t('downPayment.title')}
      subtitle={i18next.t('downPayment.subtitle', { amount: firstInstallmentAmount })}
      goBack={(): void => {
        history.push(`/payment-option/${optionUuid}`);
      }}
    >
      <Grid container direction="column">
        <TextField
          required
          error={hasSubmitted && isEmpty(fullName)}
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('downPayment.cardEntry.nameOnCard')}
          onChange={cardNameChange}
          value={fullName}
        />
        <StripeInputField
          inputProps={{ options: { showIcon: true } }}
          label={i18next.t('downPayment.cardEntry.cardNumber')}
          className={classes.textEntry}
          id="standard-basic"
          error={Boolean(cardNumberError)}
          stripeElement={CardNumberElement}
          labelErrorMessage={cardNumberError}
          onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
        />
        <Grid container justifyContent="space-between" direction="row">
          <StripeInputField
            label={i18next.t('downPayment.cardEntry.expiryDate')}
            className={`${classes.textEntry} ${classes.smallTextEntry}`}
            id="standard-basic"
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError}
            stripeElement={CardExpiryElement}
            onChange={onElementChange('expiredComplete', 'expiredError')}
          />
          <StripeInputField
            label={'CVC'}
            className={`${classes.textEntry} ${classes.smallTextEntry}`}
            id="standard-basic"
            error={Boolean(cvcError)}
            labelErrorMessage={expiredError}
            stripeElement={CardCvcElement}
            onChange={onElementChange('cvcComplete', 'cvcError')}
          />
        </Grid>
        <TextField
          required
          className={classes.textEntry}
          error={hasSubmitted && isEmpty(postalCode)}
          id="standard-basic"
          value={postalCode}
          onChange={postalCodeChange}
          label={i18next.t('signup.addressEntry.postalCode.label')}
        />
        <FormControlLabel
          control={<Radio color="primary" onClick={(): void => setTermsAccepted(true)} required={true} />}
          label={paymentAuthorizationTerms}
        />
        <NeloButton
          isLoading={isLoading || isLoadingNetworkRequest}
          text={i18next.t('signup.addressEntry.submitButtonText')}
          className={classes.button}
          onClick={submitCard}
        />
      </Grid>
    </NeloBrandWrapper>
  );
};

const mapDispatchToProps = {
  updateCardName: (name: string): PayloadAction<string, string> => updateCardName(name),
  updatePC: (pc: string): PayloadAction<string, string> => updatePostalCode(pc),
  setErrorMessage: (errorMessage: string): PayloadAction<string, ApplicationError> =>
    updateErrorMessage({ errorMessage }),
  createCard: (payload: CreateCard): PayloadAction<string, CreateCard> => createNeloCard(payload),
  sendCardAnalyticsEvent: (name: AnalyticEventName): PayloadAction<string, AnalyticEvent> =>
    emitAnalyticEventAction({ name })
};

const mapStateToProps = ({
  downPayment,
  application,
  loan
}: {
  downPayment: DownPaymentState;
  application: ApplicationState;
  loan: LoanState;
}): StateProps => ({
  fullName: downPayment.fullName,
  postalCode: downPayment.postalCode,
  firstInstallmentAmount: loan.loanPreview?.schedule[0].value,
  loanUuid: loan.loanPreview?.loanUuid,
  isLoadingNetworkRequest: application.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(DownPayment);
