import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BerbixVerify from 'berbix-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { EmptyAction, PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { AnalyticEventName } from '../analytics/AnalyticEventName';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationError, ApplicationProps, ApplicationState, updateErrorMessage } from '../ducks/application';
import { AuthState, fetchOnfidoToken, startOnfidoCheck, submitTransaction, updateIsVerified } from '../ducks/auth';
import { DownPaymentState, getUserNeloCards, NeloCard } from '../ducks/downPayment';
import { confirmLoan, createLoan, LoanState } from '../ducks/loan';
import { clickedServerDrivenCta } from '../ducks/serverDrivenCta';
import { Action as ServerAction } from '../interfaces/nelo-api/Action';
import { LoanRejectionReason } from '../interfaces/nelo-api/ErrorDetails';
import {
  failedIdentityVerificationStates,
  IdentityVerificationStatus,
  pendingIdentityVerificationStates
} from '../interfaces/nelo-api/IdentityVerificationStatus';
import { ConfirmLoanRequest, LoanLoadingProps, LoanPreview, PaymentOption } from '../interfaces/nelo-api/Loan';
import { LoanApplicationState } from '../interfaces/nelo-api/LoanApplicationState';
import i18next from '../localization/i18n';
import { colors, spacing } from '../styling';
import { CardSection } from './card/CardsSection';
import LoadingBar from './LoadingBar';
import LoanRejection from './LoanRejection';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';
import OnfidoButton from './OnfidoButton';

export const useStyles = makeStyles({
  lastRow: {
    borderBottom: `2px solid ${colors.accent}`,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x
  },
  lastRowText: {
    fontWeight: 'bold'
  },
  breakdownHeading: {
    fontWeight: 'bold',
    marginTop: spacing.spacing2x
  },
  loanSummaryCta: {
    fontWeight: 'bold',
    marginTop: spacing.spacing2x,
    color: colors.primary,
    cursor: 'pointer'
  },
  button: {
    width: '100%',
    marginTop: spacing.spacing6x
  },
  berbix: {
    marginTop: spacing.spacing2x
  },
  breakdownValue: {
    fontWeight: 'bold'
  },
  paymentPlanText: {
    color: colors.primary,
    fontWeight: 'bold'
  },
  arrowForwardIcon: {
    color: colors.primary
  }
});

interface StateProps extends ApplicationProps {
  loanPreview: LoanPreview | null;
  userUuid: string;
  identityVerificationStatus: IdentityVerificationStatus | null;
  numberOfFailedVerificationAttempts: number;
  redirectCancelUrl: string;
  loanApplicationState: LoanApplicationState | null;
  rejectionReason: LoanRejectionReason | null;
  isLoadingCards: boolean;
  neloCards: NeloCard[];
  errorMessage: string;
  paymentOptions: PaymentOption[];
  berbixToken: string | null;
  onfidoToken: string | null;
  isVerified: boolean;
}

interface DispatchProps {
  createLoan: (repaymentUuid: string) => void;
  confirmLoan: (loanUuid: ConfirmLoanRequest) => void;
  setIsVerified: (value: boolean) => void;
  clickedServerDrivenCta: (action: ServerAction) => void;
  getCards: () => void;
  setErrorMessage: (errorMessage: string) => void;
  sendCardAnalyticsEvent: (name: AnalyticEventName) => void;
  submitTransaction: () => void;
  fetchOnfidoToken: () => void;
  startOnfidoCheck: () => void;
}

interface BreakdownItemProps {
  label: string;
  value: string;
  key: number;
}

const BreakdownItem = (props: BreakdownItemProps): ReactElement => {
  const classes = useStyles();
  const { key, label, value } = props;
  return (
    <Grid
      key={key}
      className={classes.lastRow}
      direction="row"
      container
      justifyContent="space-between"
      alignItems="center"
      alignContent="center"
    >
      <Typography variant="body2">{label}</Typography>
      <Typography className={classes.breakdownValue}>{value}</Typography>
    </Grid>
  );
};

interface PaymentPlanProps {
  quantity: number;
  amount: string;
}

const PaymentPlan = (props: PaymentPlanProps): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { quantity, amount } = props;
  return (
    <Grid
      className={classes.lastRow}
      direction="row"
      container
      justifyContent="space-between"
      alignItems="center"
      alignContent="center"
    >
      <Typography variant="body2">{i18next.t('downPayment.paymentPlan')}</Typography>
      <Typography className={classes.paymentPlanText}>
        {i18next.t('downPayment.paymentPlan.summary', { quantity, amount })}
        <IconButton
          onClick={(): void => {
            history.push('/loan-options');
          }}
        >
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </IconButton>
      </Typography>
    </Grid>
  );
};

function LoanSummaryIdVerified(props: StateProps & DispatchProps): ReactElement {
  const classes = useStyles();
  const history = useHistory();

  const loanPreview = props.loanPreview as LoanPreview;
  const { uuid }: { uuid: string } = useParams();

  const { isLoadingCards, neloCards } = props;
  const isUpfrontPaymentRequired =
    props.paymentOptions.find((option: PaymentOption) => (option.uuid = uuid))?.isUpfrontPaymentRequired ?? true;

  const { setErrorMessage, sendCardAnalyticsEvent } = props;

  const [selectedCard, setSelectedCard] = useState<NeloCard | null>(null);

  const onCardSelect = (card: NeloCard): void => {
    sendCardAnalyticsEvent('CARD_SELECTED');
    setSelectedCard(card);
  };

  useEffect((): void => {
    isUpfrontPaymentRequired && props.getCards();
  }, []);

  const confirmLoan = (): void => {
    const loanUuid = props?.loanPreview?.loanUuid as string;
    if (isUpfrontPaymentRequired) {
      confirmCardLoan(loanUuid);
    } else {
      props.confirmLoan({ loanUuid, history });
    }
  };

  const confirmCardLoan = (loanUuid: string): void => {
    if (selectedCard) {
      sendCardAnalyticsEvent('CARD_CONFIRM_LOAN');
      props.confirmLoan({ loanUuid, cardUuid: selectedCard.uuid, history });
    } else {
      sendCardAnalyticsEvent('CARD_ERROR_NOT_SELECTED');
      setErrorMessage(i18next.t('errors.downPayment.noCardSelected'));
    }
  };

  return (
    <NeloBrandWrapper title={i18next.t('loanSummary.title')}>
      <Grid>
        <Typography className={classes.breakdownHeading} variant="subtitle1">
          {loanPreview?.breakdownLabel || ''}
        </Typography>
        {loanPreview?.schedule && loanPreview.schedule.length > 0 && (
          <PaymentPlan quantity={loanPreview.schedule.length} amount={loanPreview.schedule[0].value} />
        )}
        {loanPreview?.breakdown.map((breakdown, i) => {
          return <BreakdownItem label={breakdown.label} value={breakdown.value} key={i} />;
        })}

        {isUpfrontPaymentRequired && (
          <CardSection
            errorMessage={props.errorMessage}
            sendCardAnalyticsEvent={sendCardAnalyticsEvent}
            onCardSelect={onCardSelect}
            cards={neloCards}
            isLoadingCards={isLoadingCards}
          />
        )}

        <Typography className={classes.breakdownHeading} variant="subtitle1">
          {loanPreview?.scheduleLabel || ''}
        </Typography>
        {loanPreview?.schedule.map((breakdown, i) => {
          return (
            <Grid key={i} direction="row" container justifyContent="space-between">
              <Typography color="secondary" variant="body2">
                {breakdown.label}
              </Typography>
              <Typography>{breakdown.value}</Typography>
            </Grid>
          );
        })}
        <Typography
          className={classes.loanSummaryCta}
          variant="subtitle1"
          onClick={(): void => props.clickedServerDrivenCta(loanPreview.viewContractAction)}
        >
          {loanPreview.viewContractAction.label}
        </Typography>
        <NeloButton
          isLoading={props.isLoading}
          text={i18next.t('loanSummary.buttonText')}
          className={classes.button}
          onClick={confirmLoan}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

function LoanSummaryIdPending(props: LoanLoadingProps): ReactElement {
  const LOAN_SUMMARY_WAIT_TIME = 60;

  return (
    <NeloBrandWrapper title={i18next.t('loanSummary.pending.title')}>
      <LoadingBar
        stepIdentifier={props.stepIdentifier}
        listeningTo={props.listeningTo}
        maxLoadingTime={LOAN_SUMMARY_WAIT_TIME}
        finishedLoading={props.finishedLoading}
        message={i18next.t('loanSummary.pending.loadingDescription')}
      />
      <Typography>{i18next.t('loanSummary.pending.description')}</Typography>
    </NeloBrandWrapper>
  );
}

interface IdUnverifiedProps {
  title: string;
  subtitle: string;
  finishedLoading: () => void;
  isVerified: boolean;
  setIsVerified: (set: boolean) => void;
  listeningTo: LoanApplicationState | IdentityVerificationStatus;
}

function LoanSummaryIdUnverified(props: DispatchProps & StateProps & IdUnverifiedProps): ReactElement {
  const classes = useStyles();
  const { uuid }: { uuid: string } = useParams();
  useEffect((): void => {
    if (props.onfidoToken === null) {
      props.fetchOnfidoToken();
    }
  }, []);

  const handleComplete = (action: 'exited' | 'error' | 'finish'): void => {
    props.startOnfidoCheck();
    if (action === 'finish') {
      props.setIsVerified(true);
    }

    props.createLoan(uuid);
  };

  return (
    <NeloBrandWrapper title={props.title} subtitle={props.subtitle}>
      <Grid container direction={'column'} className={classes.berbix}>
        {props.onfidoToken && !props.isVerified && (
          <OnfidoButton onComplete={handleComplete} onfidoToken={props.onfidoToken} />
        )}
      </Grid>
    </NeloBrandWrapper>
  );
}

interface MaxAttemptsFailedProps {
  title: string;
  subtitle: string;
}

function MaxFailingVerificationAttemptsReached(
  props: DispatchProps & StateProps & MaxAttemptsFailedProps
): ReactElement {
  const classes = useStyles();
  const onClick = (): void => {
    window.location.href = props.redirectCancelUrl;
  };

  return (
    <NeloBrandWrapper title={props.title} subtitle={props.subtitle}>
      <NeloButton
        isLoading={props.isLoading}
        text={i18next.t('loanSummary.maxFailingVerificationAttemptsReached.button')}
        className={classes.button}
        onClick={(): void => onClick()}
      />
    </NeloBrandWrapper>
  );
}

function LoanSummaryLoading(): ReactElement {
  return <NeloBrandWrapper></NeloBrandWrapper>;
}

function LoanSummary(props: StateProps & DispatchProps): ReactElement {
  const { identityVerificationStatus, numberOfFailedVerificationAttempts, loanApplicationState, loanPreview } = props;

  const maxNumberOfFailingVerificationAttempts = 3;
  const [finishedLoading, setFinishedLoading] = useState(false);

  const { uuid }: { uuid: string } = useParams();

  useEffect(() => {
    if (uuid && uuid !== '') {
      props.createLoan(uuid);
    }
  }, []);

  const updateLoadingState = (): void => {
    setFinishedLoading(true);
  };

  if (loanApplicationState === 'REJECTED') {
    return <LoanRejection rejectionReason={props.rejectionReason} />;
  } else if ((identityVerificationStatus === 'VERIFIED' && loanPreview !== null) || props.isVerified) {
    return finishedLoading ? (
      <LoanSummaryIdVerified {...props} />
    ) : (
      <LoanSummaryIdPending
        stepIdentifier={uuid}
        listeningTo={identityVerificationStatus}
        finishedLoading={updateLoadingState}
      />
    );
  } else if (identityVerificationStatus && failedIdentityVerificationStates.includes(identityVerificationStatus)) {
    if (numberOfFailedVerificationAttempts >= maxNumberOfFailingVerificationAttempts) {
      return (
        <MaxFailingVerificationAttemptsReached
          {...props}
          title={i18next.t('loanSummary.maxFailingVerificationAttemptsReached.title')}
          subtitle={i18next.t('loanSummary.maxFailingVerificationAttemptsReached.subtitle')}
        />
      );
    } else {
      return (
        <LoanSummaryIdUnverified
          {...props}
          title={i18next.t('loanSummary.unverified.title')}
          subtitle={i18next.t('loanSummary.unverified.subtitle')}
          listeningTo={identityVerificationStatus}
          finishedLoading={updateLoadingState}
          setIsVerified={props.setIsVerified}
          isVerified={props.isVerified}
        />
      );
    }
  } else if (identityVerificationStatus && pendingIdentityVerificationStates.includes(identityVerificationStatus)) {
    return (
      <LoanSummaryIdPending
        stepIdentifier={uuid}
        listeningTo={identityVerificationStatus}
        finishedLoading={updateLoadingState}
      />
    );
  } else {
    return <LoanSummaryLoading />;
  }
}

const mapDispatchToProps = {
  setIsVerified: (value: boolean): PayloadAction<string, boolean> => updateIsVerified(value),
  createLoan: (uuid: string): PayloadAction<string, string> => createLoan(uuid),
  confirmLoan: (request: ConfirmLoanRequest): PayloadAction<string, ConfirmLoanRequest> => confirmLoan(request),
  clickedServerDrivenCta: (action: ServerAction): PayloadAction<string, ServerAction> => clickedServerDrivenCta(action),
  getCards: (): EmptyAction<string> => getUserNeloCards(),
  setErrorMessage: (errorMessage: string): PayloadAction<string, ApplicationError> =>
    updateErrorMessage({ errorMessage }),
  submitTransaction: (): EmptyAction<string> => submitTransaction(),
  fetchOnfidoToken: (): EmptyAction<string> => fetchOnfidoToken(),
  startOnfidoCheck: (): EmptyAction<string> => startOnfidoCheck(),
  sendCardAnalyticsEvent: (name: AnalyticEventName): PayloadAction<string, AnalyticEvent> =>
    emitAnalyticEventAction({ name })
};

const mapStateToProps = ({
  loan,
  auth,
  application,
  downPayment
}: {
  loan: LoanState;
  auth: AuthState;
  application: ApplicationState;
  downPayment: DownPaymentState;
}): StateProps => ({
  loanPreview: loan.loanPreview,
  identityVerificationStatus: loan.identityVerificationStatus,
  numberOfFailedVerificationAttempts: loan.numberOfFailedVerificationAttempts,
  loanApplicationState: loan.loanApplicationState,
  rejectionReason: loan.rejectionReason,
  userUuid: auth.userUuid,
  isLoading: application.isLoading,
  redirectCancelUrl: application.redirectCancelUrl,
  isLoadingCards: downPayment.isLoadingCards,
  neloCards: downPayment.neloCards,
  errorMessage: application.errorMessage,
  paymentOptions: loan.paymentOptions,
  berbixToken: auth.berbixToken,
  onfidoToken: auth.onfidoToken,
  isVerified: auth.isVerified
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanSummary);
