import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { ReactElement } from 'react';

import { isProd } from '../util/Environment';
import { StripeKeys } from '../util/StripeKeys';
import DebitCardForm from './DebitCardForm';

const DebitCardEntry = (): ReactElement => {
  const stripePromise = isProd() ? loadStripe(StripeKeys.production) : loadStripe(StripeKeys.development);
  return (
    <Elements stripe={stripePromise}>
      <DebitCardForm />
    </Elements>
  );
};

export default DebitCardEntry;
