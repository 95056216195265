import { StrictEffect, Task } from '@redux-saga/types';
import { call, cancel, delay, fork, put, takeLatest } from 'typed-redux-saga';
import { createAction, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { ApiError, InsufficientFundsError, LoanAmountError, LoanRejectionError } from '../errors/NeloApi';
import { GetBnplOrderPaymentOptionsResponse } from '../interfaces/nelo-api/Loan';
import { doNeloApiRequestWithResponse, NeloApiRequestFn } from '../util/neloApiRequest';
import { updateErrorMessage } from './application';
import { fetchLoanOptions, updatePaymentOptions } from './loan';

const POLLING_DELAY = 6000;

export const stopPollAction = createAction('loan/STOP_FETCH_OPTIONS_POLL')<ApiError | undefined>();

/**
 * Explicitly cancelling a provided task
 *
 * @param {Task} pollTask
 * @method *cancelPolling
 */
export function* cancelPolling(
  pollTask: Task,
  action: PayloadAction<string, ApiError | undefined>
): Generator<StrictEffect, void, void> {
  yield* cancel(pollTask);
  if (action.payload) {
    yield* put(
      updateErrorMessage({
        errorMessage: action.payload.getMessage()
      })
    );
  }
}

/**
 * This method makes an API call to an endpoint within a try/catch and stops polling if the
 * endpoint returns in an error state.
 *
 * Cancelling polling ensures that repeated bad calls (404/500) won't continue to hammer the server
 *
 * @method *fetchLoanOptionsSaga
 */
export function* fetchLoanOptionsSaga(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  const checkoutToken = action.payload;
  try {
    const responseBody: GetBnplOrderPaymentOptionsResponse = yield* call<
      NeloApiRequestFn<GetBnplOrderPaymentOptionsResponse>
    >(doNeloApiRequestWithResponse, NeloApiClient.getLoanOptions.bind(NeloApiClient, checkoutToken), false);
    const { isApproved, paymentOptions, isUpfrontPaymentRequired } = responseBody;
    yield* put(
      updatePaymentOptions({
        paymentOptions,
        loanApplicationState: isApproved ? 'APPROVED' : 'PENDING',
        isUpfrontPaymentRequired
      })
    );
    if (!isApproved) {
      return;
    }
  } catch (err) {
    if (err instanceof LoanRejectionError || err instanceof InsufficientFundsError) {
      yield* put(
        updatePaymentOptions({
          paymentOptions: [],
          loanApplicationState: 'REJECTED',
          rejectionReason: err instanceof LoanRejectionError ? err.loanRejectionReason : undefined,
          isUpfrontPaymentRequired: false
        })
      );
    } else if (err instanceof LoanAmountError) {
      yield* put(
        updatePaymentOptions({
          paymentOptions: [],
          loanApplicationState: null,
          minAllowedAmount: err.minAllowedAmount,
          isUpfrontPaymentRequired: false
        })
      );
    } else if (err instanceof ApiError) {
      yield* put(stopPollAction(err));
    } else {
      throw err;
    }
  }
  yield* put(stopPollAction(undefined));
}

/**
 * This method sets up a continuous loop to make an API request with a delay after the API call
 * returns
 *
 * @method *startPoll
 *
 */
export function* startPoll(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  while (true) {
    yield* call(fetchLoanOptionsSaga, action);
    yield* delay(POLLING_DELAY);
  }
}

/**
 * This method initializes the polling loop that does two things:
 *
 * 1. creates a forked task from the poll API call
 * 2. passes in the forked task as an argument to a watching function that will cancel a task given a certain condition (i.e. an action to stopPolling is called)
 *
 * @method pollOrCancelEndpoint
 */
export function* pollOrCancelEndpoint(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  const pollTask = yield* fork(startPoll, action);
  yield* takeLatest(stopPollAction, (action: PayloadAction<string, ApiError | undefined>) =>
    cancelPolling(pollTask, action)
  );
}

/**
 * Call this method to initiate a redux-saga based polling function with an optional cancel state
 *
 * @method pollingSaga
 */
export function* pollingSaga(): Generator<StrictEffect, void, void> {
  yield* takeLatest(fetchLoanOptions, pollOrCancelEndpoint);
}
