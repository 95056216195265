import { InputBaseComponentProps, useTheme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import React, { ReactElement } from 'react';

type StripeElement = typeof CardCvcElement | typeof CardExpiryElement | typeof CardNumberElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>['onChange'];
  stripeElement?: T;
}

const StripeTextField = <T extends StripeElement>(props: StripeTextFieldProps<T>): ReactElement => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      required
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true
      }}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement
        },
        inputComponent: StripeInput
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...(other as any)}
    />
  );
};

const StripeInput = React.forwardRef<any, InputBaseComponentProps>(function StripeInput(props, ref) {
  const { component: Component, options, ...other } = props;
  const [mountNode, setMountNode] = React.useState<any | null>(null);
  const theme = useTheme();

  React.useImperativeHandle(
    ref,
    () => ({
      focus: (): void => mountNode.focus()
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightLight,
            fontSize: '16px',
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: fade(theme.palette.text.secondary, 0.42),
              fontFamily: theme.typography.fontFamily,
              fontWeight: theme.typography.fontWeightLight
            }
          },
          invalid: {
            color: 'black'
          }
        }
      }}
      {...other}
    />
  );
});

export function StripeInputField(props: StripeTextFieldProps<StripeElement>, element: StripeElement): ReactElement {
  return <StripeTextField stripeElement={element} {...props} />;
}
