import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { Countries, Country } from '../constants/countries';
import { SignupState } from '../ducks/signup';
import { updateCountry } from '../ducks/signup';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  formControl: {
    marginTop: spacing.spacing1x,
    marginBottom: spacing.spacing1x,
    minWidth: 120
  },
  button: {
    paddingLeft: spacing.spacing4x,
    paddingRight: spacing.spacing4x
  }
});

interface DispatchProps {
  updateCountry: (country: string) => void;
}

interface StateProps {
  country?: string;
}

interface SelectElement {
  value: unknown;
}

interface LangCountries {
  [index: string]: string;
}

function CountryEntry(props: DispatchProps & StateProps): ReactElement {
  const history = useHistory();
  const classes = useStyles();

  const lang: string = navigator.language.split('-')[0] || 'es';
  const langCountries: LangCountries = Countries[lang];
  const selectedCountries: Country[] = [];

  for (const [key, value] of Object.entries(langCountries)) {
    selectedCountries.push({
      value: key,
      label: value
    });
  }

  return (
    <NeloBrandWrapper title={i18next.t('internationalCountry.title')} stepperIndex={1}>
      <Grid container direction="column">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="country-entry">{i18next.t('internationalCountry.input.label')}</InputLabel>
          <Select
            native
            value={props.country}
            onChange={(e: React.ChangeEvent<SelectElement>): void => props.updateCountry(e.target.value as string)}
            label={i18next.t('internationalCountry.entry.label')}
            inputProps={{
              name: 'country',
              id: 'country-entry'
            }}
          >
            <option aria-label="None" value="" />
            {selectedCountries.map(country => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <NeloButton
          disabled={!Boolean(props.country)}
          text={i18next.t('internationalCountry.buttonText')}
          className={classes.button}
          onClick={(): void => history.push('/signup/occupation-entry')}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  updateCountry: (country: string): PayloadAction<string, string> => updateCountry(country)
};

const mapStateToProps = ({ signup }: { signup: SignupState }): StateProps => ({
  country: signup.birthCountryIso
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryEntry);
