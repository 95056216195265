import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { History } from 'history';
import React, { ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { ApplicationProps, ApplicationState } from '../ducks/application';
import { SignupState, submitPersonalInfo, updateCurp, updateEmail, updatePin } from '../ducks/signup';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  textEntry: {
    marginTop: spacing.spacing2x,
    marginBottom: spacing.spacing2x
  },
  button: {
    marginTop: spacing.spacing2x
  }
});

interface DispatchProps {
  updatePin: (pin: string) => void;
  updateEmail: (email: string) => void;
  updateCurp: (curp: string) => void;
  submitPersonalInfo: (history: History) => void;
}

interface StateProps extends ApplicationProps {
  pin: string;
  curp: string;
  email: string;
}

function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) && validateEmailSpelling(email);
}

function validateEmailSpelling(email: string): boolean {
  const domain = email.split('@')[1];
  const domainName = domain.split('.')[0];
  const domainBase = domain.split('.')[1];
  const invalidCombo = ['hotmail', 'yahoo', 'gmail', 'co'];
  const invalidDomainBases = ['cm', 'nt'];
  const invalidDomainNames = [
    'gmial',
    'gnail',
    'gmaill',
    'yaho',
    'yaoo',
    'yahooo',
    'yhaoo',
    'gnial',
    'hotmai',
    'hotmiall',
    'hotmaill',
    'hotmall'
  ];
  if (invalidDomainNames.includes(domainName)) {
    return false;
  }
  if (invalidDomainBases.includes(domainBase)) {
    return false;
  }
  if (invalidCombo.includes(domainName) && invalidCombo.includes(domainBase)) {
    return false;
  }
  return true;
}

function validateCurp(curp: string): boolean {
  const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  return re.test(curp.toUpperCase());
}

const isConsecutiveDigits = (input: string): boolean => '0123456789'.includes(input) || '9876543210'.includes(input);

function validatePin(pin: string): boolean {
  if (pin.length !== 4 || !/^\d+$/.test(pin)) {
    return false;
  }

  if (pin.split('').every(letter => letter === pin[0])) {
    return false;
  }

  return [pin.substring(0, 3), pin.substring(1, 4)].every(seq => !isConsecutiveDigits(seq));
}

function PersonalInfoEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = useStyles();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const history = useHistory();
  return (
    <NeloBrandWrapper
      title={i18next.t('signup.createAccount.title')}
      subtitle={i18next.t('signup.createAccount.subtitle')}
      stepperIndex={0}
    >
      <Grid container direction="column">
        <TextField
          error={!validateEmail(props.email)}
          helperText={!validateEmail(props.email) && i18next.t('signup.createAccount.email.validationError')}
          required
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('signup.createAccount.email.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateEmail(e.target.value)}
          value={props.email}
        />
        <TextField
          error={hasSubmitted && !validateCurp(props.curp)}
          required
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('signup.createAccount.curp.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateCurp(e.target.value)}
          value={props.curp}
        />
        <TextField
          error={hasSubmitted && !validatePin(props.pin)}
          helperText={hasSubmitted && !validatePin(props.pin) && i18next.t('signup.createAccount.pin.validationError')}
          required
          placeholder={'----'}
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('signup.createAccount.pin.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updatePin(e.target.value)}
          value={props.pin}
          type="password"
          inputProps={{ maxLength: 4 }}
        />
        <NeloButton
          isLoading={props.isLoading}
          text={i18next.t('signup.createAccount.submitButtonText')}
          className={classes.button}
          onClick={(): void => {
            setHasSubmitted(true);
            if (validateEmail(props.email) && validateCurp(props.curp) && validatePin(props.pin)) {
              props.submitPersonalInfo(history);
            }
          }}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  submitPersonalInfo: (history: History): PayloadAction<string, History> => submitPersonalInfo(history),
  updatePin: (pin: string): PayloadAction<string, string> => updatePin(pin),
  updateEmail: (email: string): PayloadAction<string, string> => updateEmail(email),
  updateCurp: (curp: string): PayloadAction<string, string> => updateCurp(curp)
};

const mapStateToProps = ({
  signup,
  application
}: {
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  pin: signup.pin,
  email: signup.email,
  curp: signup.curp,
  isLoading: application.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoEntry);
