import React, { ReactElement, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationState } from '../ducks/application';
import { AuthState } from '../ducks/auth';

interface StateProps {
  userUuid: string;
  merchantName: string;
}

interface DispatchProps {
  logError: (event: AnalyticEvent) => void;
}

interface ApplicationWrapperProps extends StateProps, DispatchProps {
  children: ReactNode;
}
const ApplicationErrorWrapper = (props: ApplicationWrapperProps): ReactElement => {
  const history = useHistory();
  const { userUuid, merchantName, logError } = props;

  useEffect(() => {
    listenToApplicationErrors();
  }, []);

  const listenToApplicationErrors = (): void => {
    window.onunhandledrejection = (e: PromiseRejectionEvent): void => {
      logError({
        name: 'WEB_APPLICATION_ERROR',
        params: {
          errorMessage: e.reason.toString(),
          userUuid,
          merchantName,
          at: new Date().toTimeString()
        }
      });
      history.push('/error');
    };
  };

  return <>{props.children}</>;
};

const mapDispatchToProps: DispatchProps = {
  logError: (event: AnalyticEvent): PayloadAction<string, AnalyticEvent> => emitAnalyticEventAction(event)
};

const mapStateToProps = ({ auth, application }: { auth: AuthState; application: ApplicationState }): StateProps => ({
  userUuid: auth.userUuid,
  merchantName: application.merchantName
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationErrorWrapper);
