import { StrictEffect } from '@redux-saga/types';
import { History } from 'history';
import { all, call, debounce, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import NeloApiClient from '../clients/NeloApiClient';
import { isMexicanState, MexicanState } from '../constants/mexicanstates';
import { InvalidApplicationStateError } from '../errors/InvalidApplicationState';
import { Address } from '../interfaces/nelo-api/Address';
import { AddressAutocompleteResponse, SearchAddress } from '../interfaces/nelo-api/AddressAutocomplete';
import { GeoLocation } from '../interfaces/nelo-api/DeviceState';
import { LoginResponse } from '../interfaces/nelo-api/Login';
import { SignupRequest } from '../interfaces/nelo-api/Signup';
import { ValidateCurpResponse } from '../interfaces/nelo-api/ValidateCurp';
import { getPosition } from '../util/GeoLocation';
import { doNeloApiRequestWithResponse, NeloApiRequestFn, safeCall } from '../util/neloApiRequest';
import { getState as getApplicationState } from './application';
import { getState as getAuthState, updateAuthState } from './auth';

export const updatePin = createAction('signup/UPDATE_PIN_CODE')<string>();
export const updateEmail = createAction('signup/UPDATE_EMAIL')<string>();
export const updateCurp = createAction('signup/UPDATE_CURP')<string>();
export const updateCurpPersonalInfo = createAction('signup/UPDATE_CURP_INFO')<ValidateCurpResponse>();
export const submitPersonalInfo = createAction('signup/SUBMIT_PERSONAL_INFO')<History>();

export const updateOccupation = createAction('signup/UPDATE_OCCUPATION')<string>();
export const updateCountry = createAction('signup/UPDATE_COUNTRY')<string>();

export const updateAutocompleteAddresses = createAction('signup/UPDATE_AUTOCOMPLETE_ADDRESSES')<SearchAddress[]>();
export const updateAddress = createAction('signup/UPDATE_ADDRESS')<Address>();
export const updatePlaceId = createAction('signup/UPDATE_PLACE_ID')<string>();
export const updateStreet = createAction('signup/UPDATE_STREET')<string>();
export const updateBuildingNumber = createAction('signup/UPDATE_BUILDING_NUMBER')<string>();
export const updateInteriorNumber = createAction('signup/UPDATE_INTERIOR_NUMBER')<string>();
export const updateColony = createAction('signup/UPDATE_COLONY')<string>();
export const updateCity = createAction('signup/UPDATE_CITY')<string>();
export const updateState = createAction('signup/UPDATE_STATE')<string>();
export const updatePostalCode = createAction('signup/UPDATE_POSTAL_CODE')<string>();
export const submitSignup = createAction('signup/SUBMIT_SIGNUP')<History>();

interface AddressState {
  street: string;
  buildingNumber: string;
  interiorNumber: string;
  colony: string;
  city: string;
  state: MexicanState | '';
  postalCode: string;
  placeId: string | null;
}

export interface SignupState {
  pin: string;
  curp: string;
  email: string;
  session: string;
  occupation: string;
  birthCountryIso?: string;
  validateCurpResponse: ValidateCurpResponse | null;
  address: AddressState;
  autocompleteAddresses: SearchAddress[];
}

export const initialState: SignupState = {
  pin: '',
  curp: '',
  email: '',
  occupation: '',
  validateCurpResponse: null,
  session: uuidv4(),
  address: {
    street: '',
    buildingNumber: '',
    interiorNumber: '',
    city: '',
    state: '',
    colony: '',
    postalCode: '',
    placeId: null
  },
  autocompleteAddresses: []
};

const getState = ({ signup }: { signup: SignupState }): SignupState => signup;

const setPin = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  pin: action.payload
});

const setCurp = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  curp: action.payload
});

const setEmail = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  email: action.payload
});

const setOccupation = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  occupation: action.payload
});

const setCountry = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  birthCountryIso: action.payload
});

const setAutocompleteAddresses = (state: SignupState, action: PayloadAction<string, SearchAddress[]>): SignupState => ({
  ...state,
  autocompleteAddresses: action.payload
});

const setAddress = (state: SignupState, action: PayloadAction<string, Address>): SignupState => ({
  ...state,
  address: {
    buildingNumber: action.payload.addressMX?.buildingNumber || '',
    interiorNumber: action.payload.addressMX?.interiorNumber || '',
    street: action.payload.addressMX?.street || '',
    colony: action.payload.addressMX?.colony || '',
    city: action.payload.addressMX?.city || '',
    state: isMexicanState(action.payload.addressMX?.state) ? action.payload.addressMX?.state || '' : '',
    postalCode: action.payload.addressMX?.postalCode || '',
    placeId: action.payload.placeId
  }
});

const setCurpPersonalInfo = (state: SignupState, action: PayloadAction<string, ValidateCurpResponse>): SignupState => ({
  ...state,
  validateCurpResponse: action.payload
});

const setStreet = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    street: action.payload
  }
});

const setInteriorNumber = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    interiorNumber: action.payload
  }
});

const setBuildingNumber = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    buildingNumber: action.payload
  }
});

const setColony = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    colony: action.payload
  }
});

const setCity = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    city: action.payload
  }
});

const setState = (state: SignupState, action: PayloadAction<string, MexicanState>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    state: action.payload
  }
});

const setPostalCode = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    postalCode: action.payload
  }
});

function* validateCurpSaga(action: PayloadAction<string, History>): Generator<StrictEffect, void, void> {
  const state = yield* select(getState);
  const curp = state.curp;

  const history = action.payload;

  const responseBody = yield* call<NeloApiRequestFn<ValidateCurpResponse>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.validateCurp.bind(NeloApiClient, curp)
  );
  yield* put(updateCurpPersonalInfo(responseBody));
  history.push('/signup/verify-personal-info');
}

function* fetchAutocompleteAddresses(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  // Don't try to fetch autocomplete address if user is not logged in
  // This can occur when street is set from url parameters when web checkout first loads
  const authState = yield* select(getAuthState);
  if (!authState.accessToken) return;
  const input = action.payload;
  const state = yield* select(getState);
  const session = state.session;
  const responseBody = yield* call<NeloApiRequestFn<AddressAutocompleteResponse>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.autocompleteAddress.bind(NeloApiClient, input, session)
  );
  yield* put(updateAutocompleteAddresses(responseBody.addresses));
}

function* fetchAddress(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  const state = yield* select(getState);
  const placeId = action.payload;
  const session = state.session;
  const responseBody = yield* call<NeloApiRequestFn<Address>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.getAddress.bind(NeloApiClient, placeId, session)
  );
  yield* put(updateAddress(responseBody));
}

async function getGeoLocation(): Promise<GeoLocation | null> {
  try {
    const browserPosition = await getPosition({ timeout: 5000 });
    return {
      latitude: browserPosition.coords.latitude,
      longitude: browserPosition.coords.longitude
    };
  } catch (err) {
    if (err instanceof GeolocationPositionError) {
      return null;
    }
    throw err;
  }
}

function* doSignup(action: PayloadAction<string, History>): Generator<StrictEffect, void, void> {
  const history = action.payload;
  const state = yield* select(getState);
  const geoLocation = yield* call(getGeoLocation);
  const applicationState = yield* select(getApplicationState);

  const request: SignupRequest = {
    pin: state.pin,
    email: state.email,
    occupation: state.occupation,
    address: {
      addressMX: {
        buildingNumber: state.address.buildingNumber,
        interiorNumber: state.address.interiorNumber,
        street: state.address.street,
        colony: state.address.colony,
        city: state.address.city,
        state: state.address.state || null,
        postalCode: state.address.postalCode,
        delegation: null
      },
      placeId: state.address.placeId,
      countryIso2: 'MX'
    },
    curp: state.curp,
    deviceState: {
      location: geoLocation
    },
    bnplOrderUuid: applicationState.orderUuid
  };

  if (state.birthCountryIso) {
    request.birthCountryIso = state.birthCountryIso;
  }

  const responseBody = yield* call<NeloApiRequestFn<LoginResponse>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.signup.bind(NeloApiClient, request)
  );
  const { authAction, token, userUuid } = responseBody;
  yield* put(
    updateAuthState({
      accessToken: token,
      authAction,
      userUuid
    })
  );
  if (authAction === 'API') {
    const applicationState = yield* select(getApplicationState);

    history.push(`/loan-options?checkoutToken=${applicationState.checkoutToken}`);
  } else {
    throw new InvalidApplicationStateError(`Invalid authAction. Was ${authAction}`);
  }
}

export function* signupSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(submitPersonalInfo, safeCall, validateCurpSaga),
    takeEvery(submitSignup, safeCall, doSignup),
    takeEvery(updatePlaceId, safeCall, fetchAddress),
    debounce(500, updateStreet, safeCall, fetchAutocompleteAddresses)
  ]);
}

export default createReducer(initialState)
  .handleAction(updateStreet, setStreet)
  .handleAction(updateCity, setCity)
  .handleAction(updateColony, setColony)
  .handleAction(updateBuildingNumber, setBuildingNumber)
  .handleAction(updateInteriorNumber, setInteriorNumber)
  .handleAction(updatePostalCode, setPostalCode)
  .handleAction(updateState, setState)
  .handleAction(updatePin, setPin)
  .handleAction(updateCurp, setCurp)
  .handleAction(updateCountry, setCountry)
  .handleAction(updateOccupation, setOccupation)
  .handleAction(updateAutocompleteAddresses, setAutocompleteAddresses)
  .handleAction(updateAddress, setAddress)
  .handleAction(updateCurpPersonalInfo, setCurpPersonalInfo)
  .handleAction(updateEmail, setEmail);
