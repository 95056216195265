import MuiAlert from '@material-ui/core/Alert';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import {
  makeStyles,
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { EmptyAction } from 'typesafe-actions';
import { useDebouncedCallback } from 'use-debounce';

import { ApplicationState, clearErrorMessage, exitCheckoutAction } from '../ducks/application';
import i18next from '../localization/i18n';
import NeloLogo from '../nelo_logo.svg';
import { colors, spacing } from '../styling';

const useStyles = makeStyles({
  bannerContainer: {
    marginTop: spacing.spacing3x
  },
  title: {
    fontWeight: 'bold',
    marginBottom: spacing.spacing2x
  },
  icon: {
    marginBottom: spacing.spacing1x,
    display: 'block'
  },
  merchantName: {
    fontWeight: 'bold'
  },
  brandTitle: {
    color: colors.secondary
  },
  subtitle: {
    marginBottom: spacing.spacing1x
  },
  description: {
    marginBottom: spacing.spacing2x
  },
  closeIcon: {
    color: colors.black
  },
  mainContainer: {
    paddingBottom: spacing.spacing4x
  }
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    }
  }
});

interface StateProps {
  redirectCancelUrl: string;
  errorMessage: string;
  merchantName: string;
}

interface DispatchProps {
  clearErrorMessage: () => void;
  exitCheckout: () => void;
}

interface NeloBrandWrapperProps {
  title?: string;
  subtitle?: string;
  goBack?: () => void;
  description?: string;
  stepperIndex?: number;
  children?: ReactElement | ReactElement[];
}

function getSteps(): string[] {
  return [
    i18next.t('stepper.createAccount'),
    i18next.t('stepper.basicInfo'),
    i18next.t('stepper.occupation'),
    i18next.t('stepper.address')
  ];
}

function NeloBrandWrapper(props: NeloBrandWrapperProps & StateProps & DispatchProps): ReactElement {
  const { stepperIndex, merchantName } = props;
  const classes = useStyles();

  const steps = getSteps();

  const exitCheckout = useDebouncedCallback(props.exitCheckout, 500);

  return (
    <ThemeProvider theme={theme}>
      {props.redirectCancelUrl && (
        <Grid container justifyContent="space-between" direction="row-reverse">
          <IconButton onClick={exitCheckout}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          {props.goBack && (
            <IconButton onClick={(): void => props.goBack && props.goBack()}>
              <ArrowBackIcon className={classes.closeIcon} />
            </IconButton>
          )}
        </Grid>
      )}
      <Grid className={classes.mainContainer} container spacing={3} alignItems="flex-start">
        <Grid item xs={1} md={3} />
        <Grid item xs={10} md={6} className={classes.bannerContainer}>
          <img src={NeloLogo} className={classes.icon} />
          {props.merchantName && (
            <React.Fragment>
              <Typography className={classes.brandTitle} component="span">
                {i18next.t('brandWrapper.title')}
              </Typography>
              <Typography className={classes.merchantName} component="span">
                {merchantName}
              </Typography>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={1} md={3} />
        <Grid item xs={1} md={3} />
        <Grid item xs={10} md={6} container direction="column" justifyContent="center">
          <Typography variant="h4" className={classes.title}>
            {props.title}
          </Typography>
          <Typography className={classes.subtitle}>{props.subtitle}</Typography>
          <Typography className={classes.description}>{props.description}</Typography>
          {props.children}
          <Snackbar
            open={props.errorMessage !== ''}
            autoHideDuration={6000}
            onClose={(): void => props.clearErrorMessage()}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <MuiAlert onClose={(): void => props.clearErrorMessage()} severity="error" elevation={6} variant="filled">
              {props.errorMessage}
            </MuiAlert>
          </Snackbar>
        </Grid>
        <Grid item xs={1} md={3} container justifyContent="center">
          <Hidden mdDown>
            {stepperIndex !== undefined && (
              <Stepper activeStep={stepperIndex} orientation="vertical">
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent />
                  </Step>
                ))}
              </Stepper>
            )}
          </Hidden>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ application }: { application: ApplicationState }): StateProps => ({
  redirectCancelUrl: application.redirectCancelUrl,
  errorMessage: application.errorMessage,
  merchantName: application.merchantName
});

const mapDispatchToProps = {
  exitCheckout: (): EmptyAction<string> => exitCheckoutAction(),
  clearErrorMessage: (): EmptyAction<string> => clearErrorMessage()
};

export default connect(mapStateToProps, mapDispatchToProps)(NeloBrandWrapper);
