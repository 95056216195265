import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { updateCheckoutToken } from '../ducks/application';
import { AuthState } from '../ducks/auth';
import { updateCity, updateEmail, updatePostalCode, updateStreet } from '../ducks/signup';
import { updatePhoneNumber } from '../ducks/verificationCode';
import { AuthAction } from '../interfaces/nelo-api/AuthAction';

interface StateProps {
  authAction: AuthAction | null;
}

interface DispatchProps {
  updateCheckoutToken: (checkoutToken: string) => void;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateEmail: (email: string) => void;
  updateCity: (city: string) => void;
  updateStreet: (street: string) => void;
  updatePostalCode: (postalCode: string) => void;
}

interface PrivateRouteProps {
  path: string;
  children: ReactElement;
  requiredAuthActions: AuthAction[];
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute(props: StateProps & DispatchProps & PrivateRouteProps): ReactElement {
  const query = useQuery();
  useEffect(() => {
    const checkoutToken = query.get('checkoutToken');
    const phoneNumber = query.get('phoneNumber');
    const email = query.get('email');
    const street = query.get('street');
    const city = query.get('city');
    const postalCode = query.get('postalCode');
    if (checkoutToken) {
      props.updateCheckoutToken(checkoutToken);
    }
    if (phoneNumber) {
      props.updatePhoneNumber(phoneNumber);
    }
    if (email) {
      props.updateEmail(email);
    }
    if (street) {
      props.updateStreet(street);
    }
    if (city) {
      props.updateCity(city);
    }
    if (postalCode) {
      props.updatePostalCode(postalCode);
    }
  }, []);

  return (
    <Route
      path={props.path}
      render={({ location }): ReactElement =>
        props.authAction !== null && props.requiredAuthActions.includes(props.authAction) ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: props.authAction === 'API' ? '/loan-options' : '/phone-entry',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapDispatchToProps = {
  updateCheckoutToken: (checkoutToken: string): PayloadAction<string, string> => updateCheckoutToken(checkoutToken),
  updatePhoneNumber: (phoneNumber: string): PayloadAction<string, string> => updatePhoneNumber(phoneNumber),
  updateEmail: (email: string): PayloadAction<string, string> => updateEmail(email),
  updateStreet: (street: string): PayloadAction<string, string> => updateStreet(street),
  updateCity: (city: string): PayloadAction<string, string> => updateCity(city),
  updatePostalCode: (postalCode: string): PayloadAction<string, string> => updatePostalCode(postalCode)
};

const mapStateToProps = ({ auth }: { auth: AuthState }): StateProps => ({
  authAction: auth.authAction
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
