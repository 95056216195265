import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { ApplicationState } from '../ducks/application';
import { fetchLoanOptions, LoanState } from '../ducks/loan';
import { LoanRejectionReason } from '../interfaces/nelo-api/ErrorDetails';
import { LoanLoadingProps, PaymentOption } from '../interfaces/nelo-api/Loan';
import { LoanApplicationState } from '../interfaces/nelo-api/LoanApplicationState';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import LoadingBar from './LoadingBar';
import LoanRejection from './LoanRejection';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';
import UnsupportedLoanAmount from './UnsuportedLoanAmount';

export const useStyles = makeStyles({
  card: {
    marginTop: spacing.spacing1x,
    marginBottom: spacing.spacing1x,
    display: 'flex'
  },
  cardTitle: {
    fontWeight: 'bold'
  },
  smallButton: {
    paddingLeft: spacing.spacing4x,
    paddingRight: spacing.spacing4x,
    paddingTop: spacing.spacing1x,
    paddingBottom: spacing.spacing1x
  }
});

interface StateProps {
  paymentOptions: PaymentOption[];
  loanApplicationState: LoanApplicationState | null;
  rejectionReason: LoanRejectionReason | null;
  checkoutToken: string;
  minAllowedAmount: number | null;
}

interface DispatchProps {
  fetchLoanOptions: (checkoutToken: string) => void;
}

interface LoanOptionProps {
  uuid: string;
  title: string;
  subtitle: string;
  history: History;
  isUpfrontPaymentRequired: boolean;
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

function LoanOption(props: LoanOptionProps): ReactElement {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Grid direction="row" container justifyContent="space-between">
        <CardContent>
          <Typography variant="h6" className={classes.cardTitle}>
            {props.title}
          </Typography>
          <Typography variant="body1">{props.subtitle}</Typography>
        </CardContent>
        <CardActions>
          <NeloButton
            text={i18next.t('loanOptions.buttonText')}
            className={classes.smallButton}
            onClick={(): void => props.history.push(`/payment-option/${props.uuid}`)}
          />
        </CardActions>
      </Grid>
    </Card>
  );
}

function LoanOptionsApproved(props: StateProps & DispatchProps): ReactElement {
  const history = useHistory();
  return (
    <NeloBrandWrapper title={i18next.t('loanOptions.title')}>
      {props.paymentOptions.map(option => (
        <LoanOption
          key={option.uuid}
          uuid={option.uuid}
          title={option.title}
          subtitle={option.subtitle}
          history={history}
          isUpfrontPaymentRequired={option.isUpfrontPaymentRequired ?? false}
        />
      ))}
    </NeloBrandWrapper>
  );
}

function LoanOptionsPending(props: LoanLoadingProps): ReactElement {
  const LOAN_OPTIONS_WAIT_TIME = 90; // Temporarily set this to 90 seconds until we improve underwriting latency

  return (
    <NeloBrandWrapper title={i18next.t('loanOptions.loading.title')}>
      <Grid container direction="column">
        <LoadingBar
          stepIdentifier={props.stepIdentifier}
          listeningTo={props.listeningTo}
          maxLoadingTime={LOAN_OPTIONS_WAIT_TIME}
          finishedLoading={props.finishedLoading}
          message={i18next.t('loanOptions.loading.statusMessage')}
        />
        <Typography variant="body1">{i18next.t('loanOptions.loading.description')}</Typography>
      </Grid>
    </NeloBrandWrapper>
  );
}

function LoanOptionsLoading(): ReactElement {
  return <NeloBrandWrapper></NeloBrandWrapper>;
}

function LoanOptions(props: StateProps & DispatchProps): ReactElement | null {
  const { loanApplicationState, minAllowedAmount } = props;
  const query = useQuery();
  const history = useHistory();
  const [finishedLoading, setFinishedLoading] = useState(false);

  // Try to get checkout token from url params or from saved state
  const checkoutToken = query.get('checkoutToken') || props.checkoutToken;

  useEffect(() => {
    if (checkoutToken) {
      props.fetchLoanOptions(checkoutToken);
    } else {
      history.replace('/error');
    }
  }, [checkoutToken]);

  const updateLoadingState = (): void => {
    setFinishedLoading(true);
  };

  if (loanApplicationState === 'APPROVED') {
    return finishedLoading ? (
      <LoanOptionsApproved {...props} />
    ) : (
      <LoanOptionsPending
        stepIdentifier={checkoutToken}
        listeningTo={loanApplicationState}
        finishedLoading={updateLoadingState}
      />
    );
  } else if (loanApplicationState === 'REJECTED' && props.checkoutToken) {
    return finishedLoading ? (
      <LoanRejection rejectionReason={props.rejectionReason} />
    ) : (
      <LoanOptionsPending
        stepIdentifier={checkoutToken}
        listeningTo={loanApplicationState}
        finishedLoading={updateLoadingState}
      />
    );
  } else if (loanApplicationState === 'PENDING') {
    return (
      <LoanOptionsPending
        stepIdentifier={checkoutToken}
        listeningTo={loanApplicationState}
        finishedLoading={updateLoadingState}
      />
    );
  } else if (loanApplicationState === null && minAllowedAmount !== null) {
    return <UnsupportedLoanAmount minAllowedAmount={minAllowedAmount} />;
  } else {
    return <LoanOptionsLoading />;
  }
}

const mapDispatchToProps = {
  fetchLoanOptions: (checkoutToken: string): PayloadAction<string, string> => fetchLoanOptions(checkoutToken)
};

const mapStateToProps = ({ loan, application }: { loan: LoanState; application: ApplicationState }): StateProps => ({
  paymentOptions: loan.paymentOptions,
  loanApplicationState: loan.loanApplicationState,
  rejectionReason: loan.rejectionReason,
  checkoutToken: application.checkoutToken,
  minAllowedAmount: loan.minAllowedAmount
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanOptions);
