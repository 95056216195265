import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PaymentIcon from '@material-ui/icons/Payment';
import { ReactElement } from 'react';

import { colors, spacing } from '../../styling';
import BanamexLogo from '../../svg/card-logos/banamex.svg';
import BanorteLogo from '../../svg/card-logos/banorte.svg';
import HSBCLogo from '../../svg/card-logos/hsbc.svg';
import MasterCardLogo from '../../svg/card-logos/mastercard.svg';
import SantanderLogo from '../../svg/card-logos/santander.svg';
import VisaLogo from '../../svg/card-logos/visa.svg';

export const useStyles = makeStyles({
  debitCardRow: {
    height: spacing.spacing10x,
    marginTop: spacing.spacing2x,
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .1)',
    width: '100%',
    textTransform: 'none'
  },
  selectedCard: {
    height: spacing.spacing10x,
    marginTop: spacing.spacing2x,
    boxShadow: '5px 5px 10px rgba(0, 0, 0, .1)',
    width: '100%',
    textTransform: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primary
  },
  cardName: {
    fontWeight: 'bold',
    marginLeft: spacing.spacingOneAndHalfx,
    color: 'black'
  },
  cardText: {
    marginLeft: spacing.spacingOneAndHalfx,
    color: 'black'
  },
  cardLogo: {
    marginLeft: spacing.spacing2x,
    height: spacing.spacing10x,
    width: spacing.spacing8x
  },
  iconLogo: {
    marginLeft: spacing.spacing4x,
    height: spacing.spacing6x,
    width: spacing.spacing4x,
    color: 'black'
  }
});

interface CardDisplayProps {
  brand: string;
  last4: string;
  onClick: () => void;
  selected: boolean;
}

const CARD_LOGOS: { [key: string]: string } = {
  mastercard: MasterCardLogo,
  visa: VisaLogo,
  banamex: BanamexLogo,
  banorte: BanorteLogo,
  santander: SantanderLogo,
  hsbc: HSBCLogo
};

export const CardDisplay = (props: CardDisplayProps): ReactElement => {
  const classes = useStyles();
  const { brand, last4, onClick, selected } = props;

  const brandName = brand.toLowerCase();

  const logo = brandName in CARD_LOGOS ? CARD_LOGOS[brandName] : null;

  return (
    <Button onClick={onClick} className={selected ? classes.selectedCard : classes.debitCardRow}>
      <Grid container alignContent="center" alignItems="center" direction="row">
        {logo ? <img className={classes.cardLogo} src={logo} /> : <PaymentIcon className={classes.iconLogo} />}
        <Typography className={classes.cardName} variant="body2">
          {brand.toUpperCase()}
        </Typography>
        <Typography className={classes.cardText} variant="body2">
          ••••
        </Typography>
        <Typography className={classes.cardText} color="secondary" variant="body2">
          {last4}
        </Typography>
      </Grid>
    </Button>
  );
};
