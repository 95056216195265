import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import Occupations from '../constants/occupations';
import { SignupState } from '../ducks/signup';
import { updateOccupation } from '../ducks/signup';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  formControl: {
    marginTop: spacing.spacing1x,
    marginBottom: spacing.spacing1x,
    minWidth: 120
  },
  button: {
    marginTop: spacing.spacing2x
  }
});

interface DispatchProps {
  updateOccupation: (occupation: string) => void;
}

interface StateProps {
  occupation: string;
}

interface SelectElement {
  value: unknown;
}

function OccupationEntry(props: DispatchProps & StateProps): ReactElement {
  const history = useHistory();
  const classes = useStyles();
  return (
    <NeloBrandWrapper title={i18next.t('occupationEntry.title')} stepperIndex={2}>
      <Grid container direction="column">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="occupation-entry">{i18next.t('occupationEntry.input.label')}</InputLabel>
          <Select
            native
            value={props.occupation}
            onChange={(e: React.ChangeEvent<SelectElement>): void => props.updateOccupation(e.target.value as string)}
            label={i18next.t('occupationEntry.input.label')}
            inputProps={{
              name: 'occupation',
              id: 'occupation-entry'
            }}
          >
            <option aria-label="None" value="" />
            {Occupations.map(occupation => (
              <option key={occupation.value} value={occupation.value}>
                {occupation.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <NeloButton
          disabled={!Boolean(props.occupation)}
          text={i18next.t('occupationEntry.buttonText')}
          className={classes.button}
          onClick={(): void => history.push('/signup/address-entry')}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  updateOccupation: (occupation: string): PayloadAction<string, string> => updateOccupation(occupation)
};

const mapStateToProps = ({ signup }: { signup: SignupState }): StateProps => ({
  occupation: signup.occupation
});

export default connect(mapStateToProps, mapDispatchToProps)(OccupationEntry);
