import 'firebase/analytics';

import firebase from 'firebase/app';
import { all, call, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, PayloadAction } from 'typesafe-actions';

import packageJson from '../../package.json';
import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { AuthState, updateAuthState } from './auth';

export const emitAnalyticEventAction = createAction('analytics/EMIT_ANALYTIC_EVENT')<AnalyticEvent>();
export const updateAppStarted = createAction('analytics/APP_STARTED')();

// These keys are not secret. See https://stackoverflow.com/a/37484053
const firebaseConfig = {
  apiKey: 'AIzaSyASs5MDHpCjyQ2mOIwI8zONO95v97ApKZY',
  authDomain: 'nelo-mobile.firebaseapp.com',
  databaseURL: 'https://nelo-mobile.firebaseio.com',
  projectId: 'nelo-mobile',
  storageBucket: 'nelo-mobile.appspot.com',
  messagingSenderId: '216800178534',
  appId: '1:216800178534:web:d9fc04a96ae47bf08e18a7',
  measurementId: 'G-L8XQX45T50'
};

firebase.initializeApp(firebaseConfig);

function getAnalyticsUserProperties({ auth }: { auth: AuthState }): { [key: string]: string | null } {
  return {
    uuid: auth.userUuid,
    is_admin: String(auth.authAction?.includes('ADMIN')),
    version: packageJson.version
  };
}

export function* emitAnalyticEvent(action: PayloadAction<string, AnalyticEvent>): SagaGenerator<void> {
  const name = action.payload.name.toLocaleLowerCase();
  const params = action.payload.params;

  yield* call(firebase.analytics().logEvent.bind(firebase.analytics()), name, params);
}

function* setFirebaseUserProperties(): SagaGenerator<void> {
  const userProperties: { [key: string]: string | null } = yield* select(getAnalyticsUserProperties);
  const uuid = userProperties.uuid;
  yield* call(firebase.analytics().setUserProperties.bind(firebase.analytics()), userProperties);
  if (uuid) {
    yield* call(firebase.analytics().setUserId.bind(firebase.analytics()), uuid);
  }
}

export function* analyticsSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(updateAppStarted, setFirebaseUserProperties),
    takeEvery(updateAuthState, setFirebaseUserProperties),
    takeEvery(emitAnalyticEventAction, emitAnalyticEvent)
  ]);
}
