import i18next from 'i18next';

import en from './en.json';
import es from './es.json';

i18next.init({
  lng: navigator.language.split('-')[0],
  resources: {
    es: {
      translation: {
        ...es
      }
    },
    en: {
      translation: {
        ...en
      }
    }
  }
});

export default i18next;
