import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import React, { ReactElement, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { createVerificationCodeAction, MobileVerificationState, updatePhoneNumber } from '../ducks/verificationCode';
import useMobileDetect from '../hooks/useMobileDetect';
import i18next from '../localization/i18n';
import { colors, spacing } from '../styling';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  textEntry: {
    marginTop: spacing.spacing3x,
    marginBottom: spacing.spacing3x
  },
  note: {
    marginTop: spacing.spacing2x,
    color: colors.secondary
  },
  button: {
    marginBottom: spacing.spacing4x
  }
});

interface DispatchProps {
  submitPhoneNumber: (history: History) => void;
  updatePhoneNumber: (number: string) => void;
}

interface StateProps {
  phoneNumberDisplay: string;
}

function useRunAfterUpdate(): (fn: any) => any {
  const afterPaintRef = useRef(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef !== null && afterPaintRef.current) {
      /* eslint-disable */
      // @ts-ignore
      afterPaintRef.current();
      /* eslint-enable */
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = (fn: any): void => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

function countSpacesBeforeCursor(cursorIndex: number, text: string): number {
  let numSpaces = 0;
  text.split('').forEach((val, index) => {
    if (val === ' ' && index < cursorIndex) {
      numSpaces += 1;
    }
  });
  return numSpaces;
}

function handleTextChange(
  e: React.ChangeEvent<HTMLInputElement>,
  runAfterUpdate: any,
  props: DispatchProps & StateProps
): boolean {
  const input = e.target;
  const text = input.value;
  const cursor = input.selectionEnd || 0;
  const numSpacesPriorToUpdate = countSpacesBeforeCursor(cursor, text);

  props.updatePhoneNumber(text);

  runAfterUpdate(() => {
    // The phone number formatter can programmatically add spaces in the text input.
    // The addition or removal of these spaces can change the position of the cursor.
    // To keep it in the same place the user expects it to be, we have to account for
    // the new spaces and calculate the new cursor position.
    // E.g. if you type "+19172^", this will get formatted to "+1 917^ 2" so your cursor
    // which was at position 6, right after the "2", now needs to be at position 8 in order
    // to still be after the "2".
    const numSpacesPostUpdate = countSpacesBeforeCursor(cursor, input.value);

    const newCursorPosition = (cursor || 0) + (numSpacesPostUpdate - numSpacesPriorToUpdate);
    input.selectionStart = newCursorPosition;
    input.selectionEnd = newCursorPosition;
  });
  return false;
}

function PhoneEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = useStyles();
  const { isMobile } = useMobileDetect();
  const runAfterUpdate = useRunAfterUpdate();

  const history = useHistory();

  return (
    <NeloBrandWrapper
      title={i18next.t('phoneEntry.title')}
      subtitle={i18next.t('phoneEntry.subtitle')}
      description={i18next.t('phoneEntry.description')}
    >
      <Grid container direction="column">
        <TextField
          className={classes.textEntry}
          id="standard-basic"
          label={i18next.t('phoneEntry.phone.label')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): boolean => handleTextChange(e, runAfterUpdate, props)}
          value={props.phoneNumberDisplay}
        />
        <NeloButton
          text={i18next.t('phoneEntry.phone.buttonText')}
          className={classes.button}
          onClick={(): void => props.submitPhoneNumber(history)}
        />
        <Typography variant={'body2'} className={classes.note}>
          {i18next.t('phoneEntry.notes.1')}
        </Typography>
        <Typography variant={'body2'} className={classes.note}>
          {i18next.t('phoneEntry.notes.2')}
        </Typography>
        <Typography variant={'body2'} className={classes.note}>
          {i18next.t('phoneEntry.notes.3')}
        </Typography>
        {isMobile && (
          <Typography variant={'body2'} className={classes.note}>
            <Link type="link" target="_blank" href={'https://www.nelo.mx/terms'}>
              {i18next.t('phoneEntry.terms.conditions.link')}
            </Link>
          </Typography>
        )}
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  submitPhoneNumber: (history: History): PayloadAction<string, History> => createVerificationCodeAction(history),
  updatePhoneNumber: (number: string): PayloadAction<string, string> => updatePhoneNumber(number)
};

const mapStateToProps = ({ verificationCode }: { verificationCode: MobileVerificationState }): StateProps => ({
  phoneNumberDisplay: verificationCode.phoneNumberDisplay
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneEntry);
