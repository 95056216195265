import { init } from 'onfido-sdk-ui';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

interface OnfidoComponentProps {
  onComplete: (action: 'exited' | 'error' | 'finish') => void;
  onfidoToken: string | null | undefined;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'onfido-mount': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export interface ChecksProps {
  consider: any[];
  report_names: any[];
  applicant_id: string;
}

export default function OnfidoButton({ onfidoToken, onComplete }: OnfidoComponentProps): ReactElement {
  const handleFinished = useCallback(({ success }) => {
    onComplete('finish');
  }, []);

  const handleExited = useCallback(userExitCode => {
    onComplete('exited');
  }, []);

  const handleError = useCallback(({ type, message }) => {
    onComplete('error');
  }, []);

  const isEnglish = navigator.language.split('-')[0] === 'en';
  const [onfidoClient, setClient] = useState<any>(null);

  useEffect(() => {
    if (!onfidoClient) {
      setClient(
        init({
          token: onfidoToken || '',
          containerId: 'onfido-mount',
          onComplete: handleFinished,
          onError: handleError,
          onUserExit: handleExited,
          language: isEnglish ? 'en' : 'es',
          steps: [
            'welcome',
            {
              type: 'document',
              options: {
                forceCrossDevice: true,
                uploadFallback: false,
                useLiveDocumentCapture: true,
                showCountrySelection: false,
                documentTypes: {
                  national_identity_card: true
                }
              }
            },
            {
              type: 'face',
              options: {
                retries: 4,
                uploadFallback: true,
                requestedVariant: 'motion',
                useMultipleSelfieCapture: true
              }
            },
            'complete'
          ]
        })
      );
    }
  }, [onfidoClient]);

  useEffect(() => {
    if (onfidoClient && onfidoToken) {
      onfidoClient.setOptions({ token: onfidoToken });
    }
    return (): void => {
      if (onfidoClient) {
        onfidoClient.tearDown();
      }
    };
  }, [onfidoToken, onfidoClient]);

  return <div id="onfido-mount"></div>;
}
