import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { EmptyAction } from 'typesafe-actions';

import { exitCheckoutAction } from '../ducks/application';
import { LoanRejectionReason } from '../interfaces/nelo-api/ErrorDetails';
import i18next from '../localization/i18n';
import { spacing } from '../styling';
import { isProd } from '../util/Environment';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';

export const useStyles = makeStyles({
  questionText: {
    fontWeight: 'bold'
  },
  button: {
    marginTop: spacing.spacing3x,
    marginBottom: spacing.spacing3x,
    paddingLeft: spacing.spacing4x,
    paddingRight: spacing.spacing4x,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x
  }
});

interface LoanRejectedProps {
  exitCheckout: () => void;
  rejectionReason: LoanRejectionReason | null;
}

interface RejectionAnswers {
  answer1: ReactElement;
  answer2: ReactElement;
}

function getAnswers(rejectionReason: LoanRejectionReason | null): RejectionAnswers {
  if (rejectionReason === 'ACCOUNT_OVERDUE') {
    return {
      answer1: i18next.t('loanOptions.rejected.accountOverdue.answer1'),
      answer2: (
        <React.Fragment>
          {i18next.t('loanOptions.rejected.accountOverdue.answer2')}{' '}
          <Link to={{ pathname: isProd() ? 'https://app.nelo.co' : 'https://app-dev.nelo.co' }} target="_blank">
            {i18next.t('loanOptions.rejected.accountOverdue.linkText')}
          </Link>
        </React.Fragment>
      )
    };
  }

  // Generic loan application rejection answers
  return {
    answer1: i18next.t('loanOptions.rejected.answer1'),
    answer2: i18next.t('loanOptions.rejected.answer2')
  };
}

function LoanRejected(props: LoanRejectedProps): ReactElement {
  const classes = useStyles();

  const answers = getAnswers(props.rejectionReason);
  return (
    <NeloBrandWrapper title={i18next.t('loanOptions.rejected.title')}>
      <Grid container direction="column">
        <Typography variant="body1" className={classes.questionText}>
          {i18next.t('loanOptions.rejected.question1')}
        </Typography>
        <Typography variant="body1">{answers.answer1}</Typography>
        <br />
        <Typography variant="body1" className={classes.questionText}>
          {i18next.t('loanOptions.rejected.question2')}
        </Typography>
        <Typography variant="body1">{answers.answer2} </Typography>

        <NeloButton
          text={i18next.t('loanOptions.rejected.submitButtonText')}
          className={classes.button}
          onClick={(): void => props.exitCheckout()}
        />
      </Grid>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  exitCheckout: (): EmptyAction<string> => exitCheckoutAction()
};

export default connect(null, mapDispatchToProps)(LoanRejected);
