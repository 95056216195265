interface Countries {
  [index: string]: {
    [index: string]: string;
  };
}

export interface Country {
  value: string;
  label: string;
}

export const Countries: Countries = {
  es: {
    AQ: 'Antártida',
    AG: 'Antigua y Barbuda',
    SA: 'Arabia Saudí',
    DZ: 'Argelia',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaiyán',
    BS: 'Bahamas',
    BD: 'Bangladés',
    BB: 'Barbados',
    BH: 'Baréin',
    BE: 'Bélgica',
    BZ: 'Belice',
    BJ: 'Benín',
    BM: 'Bermudas',
    BY: 'Bielorrusia',
    BO: 'Bolivia',
    BA: 'Bosnia y Herzegovina',
    BW: 'Botsuana',
    BR: 'Brasil',
    BN: 'Brunéi',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    BT: 'Bután',
    CV: 'Cabo Verde',
    KH: 'Camboya',
    CM: 'Camerún',
    CA: 'Canadá',
    BQ: 'Caribe neerlandés',
    QA: 'Catar',
    TD: 'Chad',
    CZ: 'Chequia',
    CL: 'Chile',
    CN: 'China',
    CY: 'Chipre',
    VA: 'Ciudad del Vaticano',
    CO: 'Colombia',
    KM: 'Comoras',
    CG: 'Congo',
    KP: 'Corea del Norte',
    KR: 'Corea del Sur',
    CR: 'Costa Rica',
    CI: 'Côte d’Ivoire',
    HR: 'Croacia',
    CU: 'Cuba',
    CW: 'Curazao',
    DK: 'Dinamarca',
    DM: 'Dominica',
    EC: 'Ecuador',
    EG: 'Egipto',
    SV: 'El Salvador',
    AE: 'Emiratos Árabes Unidos',
    ER: 'Eritrea',
    SK: 'Eslovaquia',
    SI: 'Eslovenia',
    ES: 'España',
    US: 'Estados Unidos',
    EE: 'Estonia',
    SZ: 'Esuatini',
    ET: 'Etiopía',
    PH: 'Filipinas',
    FI: 'Finlandia',
    FJ: 'Fiyi',
    FR: 'Francia',
    GA: 'Gabón',
    GM: 'Gambia',
    GE: 'Georgia',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GD: 'Granada',
    GR: 'Grecia',
    GL: 'Groenlandia',
    GP: 'Guadalupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GF: 'Guayana Francesa',
    GG: 'Guernsey',
    GN: 'Guinea',
    GQ: 'Guinea Ecuatorial',
    GW: 'Guinea-Bisáu',
    GY: 'Guyana',
    HT: 'Haití',
    HN: 'Honduras',
    HU: 'Hungría',
    IN: 'India',
    ID: 'Indonesia',
    IQ: 'Irak',
    IR: 'Irán',
    IE: 'Irlanda',
    BV: 'Isla Bouvet',
    IM: 'Isla de Man',
    CX: 'Isla de Navidad',
    NF: 'Isla Norfolk',
    IS: 'Islandia',
    AX: 'Islas Åland',
    KY: 'Islas Caimán',
    CC: 'Islas Cocos',
    CK: 'Islas Cook',
    FO: 'Islas Feroe',
    GS: 'Islas Georgia del Sur y Sandwich del Sur',
    HM: 'Islas Heard y McDonald',
    FK: 'Islas Malvinas',
    MP: 'Islas Marianas del Norte',
    MH: 'Islas Marshall',
    UM: 'Islas menores alejadas de EE. UU.',
    PN: 'Islas Pitcairn',
    SB: 'Islas Salomón',
    TC: 'Islas Turcas y Caicos',
    VG: 'Islas Vírgenes Británicas',
    VI: 'Islas Vírgenes de EE. UU.',
    IL: 'Israel',
    IT: 'Italia',
    JM: 'Jamaica',
    JP: 'Japón',
    JE: 'Jersey',
    JO: 'Jordania',
    KZ: 'Kazajistán',
    KE: 'Kenia',
    KG: 'Kirguistán',
    KI: 'Kiribati',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesoto',
    LV: 'Letonia',
    LB: 'Líbano',
    LR: 'Liberia',
    LY: 'Libia',
    LI: 'Liechtenstein',
    LT: 'Lituania',
    LU: 'Luxemburgo',
    MK: 'Macedonia del Norte',
    MG: 'Madagascar',
    MY: 'Malasia',
    MW: 'Malaui',
    MV: 'Maldivas',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marruecos',
    MQ: 'Martinica',
    MU: 'Mauricio',
    MR: 'Mauritania',
    YT: 'Mayotte',
    MX: 'México',
    FM: 'Micronesia',
    MD: 'Moldavia',
    MC: 'Mónaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    MM: 'Myanmar (Birmania)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NI: 'Nicaragua',
    NE: 'Níger',
    NG: 'Nigeria',
    NU: 'Niue',
    NO: 'Noruega',
    NC: 'Nueva Caledonia',
    NZ: 'Nueva Zelanda',
    OM: 'Omán',
    NL: 'Países Bajos',
    PK: 'Pakistán',
    PW: 'Palaos',
    PA: 'Panamá',
    PG: 'Papúa Nueva Guinea',
    PY: 'Paraguay',
    PE: 'Perú',
    PF: 'Polinesia Francesa',
    PL: 'Polonia',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    HK: 'RAE de Hong Kong (China)',
    MO: 'RAE de Macao (China)',
    GB: 'Reino Unido',
    CF: 'República Centroafricana',
    CD: 'República Democrática del Congo',
    DO: 'República Dominicana',
    RE: 'Reunión',
    RW: 'Ruanda',
    RO: 'Rumanía',
    RU: 'Rusia',
    EH: 'Sáhara Occidental',
    WS: 'Samoa',
    AS: 'Samoa Americana',
    BL: 'San Bartolomé',
    KN: 'San Cristóbal y Nieves',
    SM: 'San Marino',
    MF: 'San Martín',
    PM: 'San Pedro y Miquelón',
    VC: 'San Vicente y las Granadinas',
    SH: 'Santa Elena',
    LC: 'Santa Lucía',
    ST: 'Santo Tomé y Príncipe',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leona',
    SG: 'Singapur',
    SX: 'Sint Maarten',
    SY: 'Siria',
    SO: 'Somalia',
    LK: 'Sri Lanka',
    ZA: 'Sudáfrica',
    SD: 'Sudán',
    SS: 'Sudán del Sur',
    SE: 'Suecia',
    CH: 'Suiza',
    SR: 'Surinam',
    SJ: 'Svalbard y Jan Mayen',
    TH: 'Tailandia',
    TW: 'Taiwán',
    TZ: 'Tanzania',
    TJ: 'Tayikistán',
    IO: 'Territorio Británico del Océano Índico',
    TF: 'Territorios Australes Franceses',
    PS: 'Territorios Palestinos',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad y Tobago',
    TN: 'Túnez',
    TM: 'Turkmenistán',
    TR: 'Turquía',
    TV: 'Tuvalu',
    UA: 'Ucrania',
    UG: 'Uganda',
    UY: 'Uruguay',
    UZ: 'Uzbekistán',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis y Futuna',
    YE: 'Yemen',
    DJ: 'Yibuti',
    ZM: 'Zambia',
    ZW: 'Zimbabue'
  },
  en: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia & Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    BQ: 'Caribbean Netherlands',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo - Brazzaville',
    CD: 'Congo - Kinshasa',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Côte d’Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard & McDonald Islands',
    HN: 'Honduras',
    HK: 'Hong Kong SAR China',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao SAR China',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar (Burma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MK: 'North Macedonia',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé & Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia & South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthélemy',
    SH: 'St. Helena',
    KN: 'St. Kitts & Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre & Miquelon',
    VC: 'St. Vincent & Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard & Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks & Caicos Islands',
    TV: 'Tuvalu',
    UM: 'U.S. Outlying Islands',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis & Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  }
};
