import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { ApplicationProps, ApplicationState } from '../ducks/application';
import {
  MobileVerificationState,
  submitVerificationCodeAction,
  updateVerificationCode
} from '../ducks/verificationCode';
import i18next from '../localization/i18n';
import NeloBrandWrapper from './NeloBrandWrapper';
import NeloButton from './NeloButton';
import { useStyles } from './PhoneEntry';

interface DispatchProps {
  submitVerificationCode: (history: History) => void;
  updateVerificationCode: (code: string) => void;
}

interface StateProps extends ApplicationProps {
  verificationCode: string;
  phoneNumberDisplay: string;
}

function MobileVerificationEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const onClick = (): void => {
    props.submitVerificationCode(history);
  };
  return (
    <NeloBrandWrapper
      title={i18next.t('confirmCode.title')}
      subtitle={i18next.t('confirmCode.subtitle', { phoneNumber: props.phoneNumberDisplay })}
    >
      <TextField
        id="standard-basic"
        className={classes.textEntry}
        label={i18next.t('confirmCode.code.label')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateVerificationCode(e.target.value)}
        value={props.verificationCode}
      />
      <NeloButton
        isLoading={props.isLoading}
        text={i18next.t('confirmCode.code.buttonText')}
        className={classes.button}
        onClick={(): void => onClick()}
      />
      <Typography variant={'body2'} className={classes.note}>
        {i18next.t('confirmCode.notes.1')}
      </Typography>
      <Typography variant={'body2'} className={classes.note}>
        {i18next.t('confirmCode.notes.2')}
      </Typography>
      <Typography variant={'body2'} className={classes.note}>
        {i18next.t('confirmCode.notes.3')}
      </Typography>
    </NeloBrandWrapper>
  );
}

const mapDispatchToProps = {
  updateVerificationCode: (code: string): PayloadAction<string, string> => updateVerificationCode(code),
  submitVerificationCode: (history: History): PayloadAction<string, History> => submitVerificationCodeAction(history)
};

const mapStateToProps = ({
  verificationCode,
  application
}: {
  verificationCode: MobileVerificationState;
  application: ApplicationState;
}): StateProps => ({
  verificationCode: verificationCode.verificationCode,
  phoneNumberDisplay: verificationCode.phoneNumberDisplay,
  isLoading: application.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerificationEntry);
