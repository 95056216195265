import React, { ReactElement, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { ApplicationState } from '../ducks/application';
import { AuthToken, submitToken } from '../ducks/auth';

interface StateProps {
  checkoutToken: string | null;
}

interface DispatchProps {
  updateAccessToken: (token: AuthToken) => void;
}

interface ApplicationWrapperProps extends StateProps, DispatchProps {
  children: ReactNode;
}
const PostMessageWrapper = (props: ApplicationWrapperProps): ReactElement => {
  const history = useHistory();

  useEffect(() => {
    postMessageListener();
  }, []);

  const tryParseJSON = (json: string): boolean => {
    try {
      const o = JSON.parse(json);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  };

  const postMessageListener = (): void => {
    window.addEventListener('message', (event: MessageEvent): void => {
      if (event?.data) {
        const eventData = tryParseJSON(event.data) ? JSON.parse(event.data) : event.data;
        if (eventData?.userUuid && eventData?.token) {
          props.updateAccessToken({
            token: eventData.token,
            userUuid: eventData.userUuid,
            history
          });
        }
      }
    });
  };

  return <>{props.children}</>;
};

const mapDispatchToProps: DispatchProps = {
  updateAccessToken: (authToken: AuthToken): PayloadAction<string, AuthToken> => submitToken(authToken)
};

const mapStateToProps = ({ application }: { application: ApplicationState }): StateProps => ({
  checkoutToken: application.checkoutToken || null
});

export default connect(mapStateToProps, mapDispatchToProps)(PostMessageWrapper);
