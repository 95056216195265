import { StrictEffect } from '@redux-saga/types';
import { Base64 } from 'js-base64';
import { all, call, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import NeloApiClient from '../clients/NeloApiClient';
import { PostMessagePayload } from '../interfaces/nelo-api/PostMessagePayload';
import { isInIframe } from '../util/Modal';
import { doNeloApiRequest, NeloApiRequestFn } from '../util/neloApiRequest';

export interface ApplicationError {
  errorMessage: string;
}

export const logout = createAction('application/LOGOUT')();
export const clearErrorMessage = createAction('application/CLEAR_ERROR_MESSAGES')();
export const updateErrorMessage = createAction('application/UPDATE_ERROR_MESSAGE')<ApplicationError>();

export const exitCheckoutAction = createAction('loan/EXIT_CHECKOUT')();
export const updateCheckoutToken = createAction('loan/UPDATE_CHECKOUT_TOKEN')<string>();

export const updateBlockingNetworkRequest = createAction('application/UPDATE_NETWORK_REQUEST')<boolean>();

export interface CheckoutTokenPayload {
  redirect_confirm_url: string;
  redirect_cancel_url: string;
  merchant_name: string;
  reference: string;
  sub: string;
}

export interface ApplicationState {
  errorMessage: string;
  isLoading: boolean;
  checkoutToken: string;
  merchantName: string;
  redirectCancelUrl: string;
  orderReference: string;
  orderUuid: string;
  sessionId: string;
}

export interface ApplicationProps {
  isLoading: boolean;
}

export const initialState: ApplicationState = {
  errorMessage: '',
  checkoutToken: '',
  merchantName: '',
  redirectCancelUrl: '',
  isLoading: false,
  orderReference: '',
  orderUuid: '',
  sessionId: uuidv4()
};

export const getState = ({ application }: { application: ApplicationState }): ApplicationState => application;

const setCheckoutToken = (state: ApplicationState, action: PayloadAction<string, string>): ApplicationState => {
  const checkoutToken = action.payload;

  const base64Payload = checkoutToken.split('.')[1];

  const decodedPayload: CheckoutTokenPayload = JSON.parse(Base64.decode(base64Payload));

  return {
    ...state,
    checkoutToken,
    merchantName: decodedPayload.merchant_name,
    redirectCancelUrl: decodedPayload.redirect_cancel_url,
    orderReference: decodedPayload.reference,
    orderUuid: decodedPayload.sub
  };
};

const setError = (state: ApplicationState, action: PayloadAction<string, ApplicationError>): ApplicationState => ({
  ...state,
  errorMessage: action.payload.errorMessage
});

const clearError = (state: ApplicationState): ApplicationState => ({
  ...state,
  errorMessage: ''
});

const setLoading = (state: ApplicationState, action: PayloadAction<string, boolean>): ApplicationState => ({
  ...state,
  isLoading: action.payload
});

function* exitCheckout(): Generator<StrictEffect, void, void> {
  const applicationState = yield* select(getState);
  yield* call<NeloApiRequestFn<void>>(
    doNeloApiRequest,
    NeloApiClient.voidCheckout.bind(NeloApiClient, applicationState.orderUuid)
  );
  if (isInIframe()) {
    const payload: PostMessagePayload = {
      paymentUuid: applicationState.orderUuid,
      reference: applicationState.orderReference,
      type: 'CANCELED'
    };
    window.parent.postMessage(payload, '*');
  } else {
    window.location.href = applicationState.redirectCancelUrl;
  }
}

export function* applicationSaga(): SagaGenerator<void> {
  yield* all([takeEvery(exitCheckoutAction, exitCheckout)]);
}

export default createReducer(initialState)
  .handleAction(updateBlockingNetworkRequest, setLoading)
  .handleAction(updateCheckoutToken, setCheckoutToken)
  .handleAction(updateErrorMessage, setError)
  .handleAction(clearErrorMessage, clearError);
