import { StrictEffect } from '@redux-saga/types';
import { History } from 'history';
import { AsYouType, CountryCode, E164Number } from 'libphonenumber-js';
import { all, call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { InvalidApplicationStateError } from '../errors/InvalidApplicationState';
import PhoneNumber from '../interfaces/nelo-api/PhoneNumber';
import { ConfirmVerificationCodeResponse } from '../interfaces/nelo-api/VerificationCode';
import i18next from '../localization/i18n';
import { doNeloApiRequest, doNeloApiRequestWithResponse, NeloApiRequestFn, safeCall } from '../util/neloApiRequest';
import { updateErrorMessage } from './application';
import { updateAuthState } from './auth';

export const updatePhoneNumber = createAction('mobileVerification/UPDATE_PHONE_NUMBER')<string>();
export const createVerificationCodeAction = createAction('mobileVerification/CREATE_VERIFICATION_CODE')<History>();
export const updateVerificationCode = createAction('mobileVerification/UPDATE_VERIFICATION_CODE')<string>();
export const submitVerificationCodeAction = createAction('mobileVerification/SUBMIT_VERIFICATION_CODE')<History>();
export const completedMobileVerification = createAction('mobileVerification/COMPLETED')();

export interface MobileVerificationState {
  countryIso2: CountryCode;
  phoneNumberDisplay: string;
  phoneNumberE164: E164Number | null;
  isPhoneNumberValid: boolean;
  verificationCode: string;
  isVerificationCodeValid: boolean;
}

export const initialState: MobileVerificationState = {
  countryIso2: 'MX', // TODO: Get country code input
  phoneNumberDisplay: '+52 ',
  phoneNumberE164: null,
  isPhoneNumberValid: false,
  verificationCode: '',
  isVerificationCodeValid: false
};

const getPhoneNumber = ({ verificationCode }: { verificationCode: MobileVerificationState }): PhoneNumber | null => {
  const { phoneNumberE164, countryIso2 } = verificationCode;
  if (phoneNumberE164 && countryIso2) {
    return {
      number: phoneNumberE164.toString(),
      countryIso2
    };
  }
  return null;
};

const getState = ({ verificationCode }: { verificationCode: MobileVerificationState }): MobileVerificationState =>
  verificationCode;

const setPhoneNumber = (
  state: MobileVerificationState,
  action: PayloadAction<string, string>
): MobileVerificationState => {
  const input = action.payload;
  const asYouTypeFormatter = new AsYouType(state.countryIso2);
  const formattedNumber = asYouTypeFormatter.input(input);
  const parsedNumber = asYouTypeFormatter.getNumber();
  return {
    ...state,
    phoneNumberDisplay: formattedNumber || '+',
    isPhoneNumberValid: (parsedNumber && parsedNumber.isValid()) || false,
    phoneNumberE164: (parsedNumber && parsedNumber.number) || null,
    countryIso2: parsedNumber?.country ?? 'MX'
  };
};

const setVerificationCode = (
  state: MobileVerificationState,
  action: PayloadAction<string, string>
): MobileVerificationState => ({
  ...state,
  verificationCode: action.payload,
  isVerificationCodeValid: action.payload.trim().length === 5
});

const resetVerificationCode = (state: MobileVerificationState): MobileVerificationState => ({
  ...state,
  verificationCode: '',
  isVerificationCodeValid: false
});

export default createReducer(initialState)
  .handleAction(updatePhoneNumber, setPhoneNumber)
  .handleAction(updateVerificationCode, setVerificationCode)
  .handleAction(createVerificationCodeAction, resetVerificationCode)
  .handleAction(completedMobileVerification, resetVerificationCode);

function* createVerificatonCode(action: PayloadAction<string, History>): Generator<StrictEffect, void, void> {
  const smsOnly = false;
  const history = action.payload;
  const phoneNumber = yield* select(getPhoneNumber);
  if (!phoneNumber) {
    yield* put(
      updateErrorMessage({
        errorMessage: i18next.t('phoneEntry.error.invalidNumber')
      })
    );
    return;
  }
  yield* call<NeloApiRequestFn<void>>(
    doNeloApiRequest,
    NeloApiClient.createMobileVerification.bind(NeloApiClient, {
      phoneNumber,
      smsOnly
    })
  );

  history.push('/confirm-code');
}

function* submitVerificationCode(action: PayloadAction<string, History>): Generator<StrictEffect, void, void> {
  const phoneNumber = yield* select(getPhoneNumber);

  if (!phoneNumber) {
    throw new InvalidApplicationStateError('Invalid null phone number');
  }

  const state = yield* select(getState);
  const history = action.payload;

  const responseBody = yield* call<NeloApiRequestFn<ConfirmVerificationCodeResponse>>(
    doNeloApiRequestWithResponse,
    NeloApiClient.confirmMobileVerification.bind(NeloApiClient, {
      phoneNumber,
      code: state.verificationCode
    })
  );

  const { authAction, token } = responseBody;
  yield* put(
    updateAuthState({
      accessToken: token,
      authAction,
      userUuid: ''
    })
  );
  if (authAction === 'LOGIN') {
    history.push('/pin-entry');
  } else if (authAction === 'SIGNUP') {
    history.push('/signup/personal-info');
  } else {
    throw new InvalidApplicationStateError(`Invalid authAction. Was ${authAction}`);
  }
  yield* put(completedMobileVerification());
}

export function* verificationCodeSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(createVerificationCodeAction, safeCall, createVerificatonCode),
    takeEvery(submitVerificationCodeAction, safeCall, submitVerificationCode)
  ]);
}
